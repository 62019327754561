import React from 'react'
import { useEffect } from 'react'
import moment from 'moment'
import { PageHeader, Card, Table, Input, Button, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Api from '../../../api/Api'
import { Role } from 'types/role'
import { useStore } from 'utils/stateStore'
import { useNavigate } from 'react-router'

class Status {
  roles: Role.AsObject[] = []
  isLoading = false
  total: number = undefined
  page = 1
  page_size = 10
  nameFilter: string = undefined
  curRoleId: number = undefined
}

export const RoleManage: React.FC<any> = () => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()

  const fetchRoles = async () => {
    status.isLoading = true
    try {
      const roles = await Api.role.getRoles({
        name: status.nameFilter,
        page: status.page - 1,
        page_size: status.page_size
      })
      status.roles = roles.record.records
      status.total = roles.record.total
    } finally {
      status.isLoading = false
    }
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  const columns: ColumnsType<Role.AsObject> = [
    {
      title: '角色',
      dataIndex: 'name'
    },
    {
      title: '成员',
      width: 120,
      align: 'center',
      dataIndex: 'user_count'
    },
    {
      title: '创建日期',
      dataIndex: 'create_time',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      width: 240,
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Button
              size="small"
              type="link"
              onClick={() => {
                navigate(`/admin_portal/role_manage/${record.id}/update`)
              }}
            >
              编辑
            </Button>
            <Popconfirm
              title="确认要删除这个角色么？"
              disabled={!!record.user_count}
              onConfirm={async () => {
                await Api.role.deleteRole(record.id)
                await fetchRoles()
              }}
            >
              <Button type="link" size="small" disabled={!!record.user_count}>
                删除
              </Button>
            </Popconfirm>
          </div>
        )
      }
    }
  ]

  return (
    <PageHeader title="角色管理" style={{ background: '#FFF' }}>
      <Card>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              navigate('/admin_portal/role_manage/create')
            }}
          >
            新建
          </Button>
          <Input.Search
            placeholder="请输入角色名"
            value={status.nameFilter}
            enterButton="搜索"
            style={{ width: 300 }}
            onChange={(ev) => {
              status.nameFilter = ev.target.value
              status.page = 1
            }}
            onSearch={fetchRoles}
          />
        </div>
        <Table<Role.AsObject>
          rowKey="id"
          style={{
            marginTop: 20
          }}
          columns={columns}
          bordered={true}
          dataSource={status.roles}
          pagination={{
            total: status.total,
            pageSize: status.page_size,
            showSizeChanger: false,
            onChange: async (page: number, page_size: number) => {
              status.page = page
              status.page_size = page_size
              await fetchRoles()
            }
          }}
        />
      </Card>
    </PageHeader>
  )
}
