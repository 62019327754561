import { Col, PageHeader, Row } from 'antd';

import img1 from 'src/assets/service/4.png';
import img2 from 'src/assets/service/5.png';
import img3 from 'src/assets/service/6.png';
import bg from 'src/assets/service/bg.png';

const Index = () => {
  return (
    <div style={{ minHeight: '100%', background: `url(${bg}) right top no-repeat`, position: 'relative' }}>
      <div style={{ position: 'absolute', left: 72, top: 84 }}>
        <h2 style={{ fontSize: 36, fontWeight: 600 }}>应用商城</h2>
        <div>
          <p style={{ fontSize: 16, fontWeight: 400 }}>ATOM 提供多款 AI API 服务供用户使用，其中包括：</p>

          <div>
            <span className="tag1">大语言类模型服务</span>
            <span className="tag1">通用物体检测服务</span>
            <span className="tag1">通用分类模型服务</span>
          </div>

          <div style={{ marginTop: 20 }}>
            <span className="tag1">闪马大交通模型服务</span>
            <span className="tag1">更多...</span>
          </div>
        </div>
      </div>

      <Row gutter={20} style={{ position: 'absolute', bottom: 40, padding: '0 20px' }}>
        <Col span={8}>
          <section className="box1" style={{ height: 288 }}>
            <img src={img3} style={{ float: 'right' }} alt="" />
            <div>
              <h3>大语言模型服务</h3>
              <p>大模型能够像人类一样处理语言，能够理解更为复杂的问题和上下文，并给出更加精准的答案</p>
            </div>
          </section>
        </Col>
        <Col span={8}>
          <section className="box1" style={{ height: 288 }}>
            <img src={img2} style={{ float: 'right' }} alt="" />
            <div>
              <h3>通用物体检测服务</h3>
              <p>ATOM提供API服务可以轻松接入您的应用，无需额外的安装和配置，即可快速使用</p>
            </div>
          </section>
        </Col>
        <Col span={8}>
          <div className="box1" style={{ height: 288 }}>
            <img src={img1} style={{ float: 'right' }} alt="" />
            <div>
              <h3>通用分类模型服务</h3>
              <p>提供私有化部署方案，确保敏感数据在客户内部安全存储和处理。能够保护知识产权、商业秘密</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
