import { Form, Input, Modal, ModalProps } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";

const InvitationDialog: React.FC<ModalProps & { onSubmit(values: any): void }> = ({ onOk, onSubmit, ...modalProps }) => {
  const [form] = useForm();
  return (
    <Modal
      width="40vw"
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      title="邀请成员"
      maskClosable={false}

      onOk={async () => {
        try {
          const values = await form.validateFields();
          onSubmit(values);
        } catch (err) {

        }
      }}
      {...modalProps}
    >
      <Form form={form}>
        <Form.Item label="用户账号" name={"member"} rules={[{ required: true }]}>
          <Input
            placeholder="请输入邀请用户账号"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default InvitationDialog;
