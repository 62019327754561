import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, PageHeader, Row } from 'antd';
import Section from 'components/Section';
import { observer } from 'mobx-react';
import React from 'react';
import { userPortalStore } from 'src/stores/userPortal';
import { format } from 'utils/date';
import userIcon from '../../assets/user.svg';

const Account = observer(() => {
  return (
    <PageHeader style={{ backgroundColor: '#fff', minHeight: '100%' }} className="atom-shadow">
      <Section title="基本信息">
        <Row>
          <Col style={{ width: 50 }}>
            <Row style={{ height: '100%' }} align={'middle'}>
              <img src={userIcon}></img>
              {/* <Avatar><UserOutlined /></Avatar> */}
            </Row>
          </Col>
          <Col style={{ width: 400 }}>
            <Row style={{ marginBottom: 10 }}>
              <Col style={{ width: 100 }}>用户账号:</Col>
              <Col>{userPortalStore.user.name}</Col>
            </Row>
            <Row>
              <Col style={{ width: 100 }}>用户名称:</Col>
              <Col>{userPortalStore.user.fullname}</Col>
            </Row>
          </Col>
          <Col>
            <Row style={{ marginBottom: 10 }}>
              <Col style={{ width: 100 }}>邮箱:</Col>
              <Col>{userPortalStore.user.email}</Col>
            </Row>
            <Row>
              <Col style={{ width: 100 }}>账号创建日期:</Col>
              <Col>{format(userPortalStore.user.created_at)}</Col>
            </Row>
          </Col>
        </Row>
      </Section>

      {/* <Section title="安全设置">

      </Section> */}
    </PageHeader>
  );
});

export default Account;
