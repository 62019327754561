import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Popconfirm, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Api from 'api/Api';
import async, { forEach } from 'async';
import EditGroupDialog from 'components/EditGroupDialog';
import MemberDialog from 'components/MemberDialog';
import AtomTable, { useAtomTableContext } from 'components/Table';
import PermissionDialog from 'components/permissionDialog';
import { find, merge } from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Pager from 'src/stores/pager';
import { userPortalStore } from 'src/stores/userPortal';
import { Member } from 'types/member';
import { UserGroup } from 'types/userGroup';
import { format } from 'utils/date';

class Status {
  data: UserGroup.AsObject[] = [];
  filteredData: UserGroup.AsObject[] = [];
  pager = new Pager();
  search = {
    name: ''
  };
  loading = false;

  permissionDialog = {
    open: false,
    group: {} as UserGroup.AsObject
  };

  groupDialog = {
    open: false,
    group: {} as UserGroup.AsObject,
    title: '',
    type: 'new' as 'new' | 'edit'
  };

  memberDialog = {
    open: false,
    group: {} as UserGroup.AsObject
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const status = new Status();

const OrgGroup = observer(() => {
  const columns: ColumnProps<UserGroup.AsObject>[] = [
    {
      title: '组名称',
      // width: 280,
      dataIndex: 'name'
    },
    {
      title: '成员数量',
      width: 200,
      dataIndex: 'members',
      render(value, record, index) {
        return <div style={{ width: 55, textAlign: 'right' }}>{value?.length}</div>;
      }
    },
    {
      title: '创建人',
      width: 180,
      dataIndex: ['creator', 'name']
    },
    {
      title: '创建时间',
      width: 180,
      dataIndex: 'created_at',
      render(value, record, index) {
        return format(value);
      }
    },
    {
      title: '操作',
      width: 260,
      fixed: 'right',
      render(value, record, index) {
        return (
          <Row>
            <a style={{ marginRight: 20 }} onClick={() => editGroup(record)}>
              编辑
            </a>
            <a style={{ marginRight: 20 }} onClick={() => editGroupMember(record)}>
              成员管理
            </a>
            <a style={{ marginRight: 20 }} onClick={() => editGroupPermission(record)}>
              权限配置
            </a>
            <Popconfirm
              title="确认要删除该用户组吗？"
              onConfirm={async () => {
                deleteGroup(record);
              }}
            >
              <a>删除</a>
            </Popconfirm>
          </Row>
        );
      }
    }
  ];

  const newGroup = () => {
    status.groupDialog.open = true;
    status.groupDialog.title = '新建用户组';
    status.groupDialog.type = 'new';
  };

  const editGroup = (group: UserGroup.AsObject) => {
    status.groupDialog.open = true;
    status.groupDialog.group = group;
    status.groupDialog.title = '编辑用户组';
    status.groupDialog.type = 'edit';
  };

  const editGroupMember = async (group: UserGroup.AsObject) => {
    await userPortalStore.fetchMembers();
    status.memberDialog.open = true;
    status.memberDialog.group = group;
  };

  const editGroupPermission = (group: UserGroup.AsObject) => {
    status.permissionDialog.open = true;
    status.permissionDialog.group = group;
  };

  const deleteGroup = async (group: UserGroup.AsObject) => {
    await Api.organization.deleteUserGroup(userPortalStore.user.tenant_id, group.id);

    fetchUserGroups();
  };

  const fetchUserGroups = async () => {
    status.loading = true;

    const { user_groups: userGroups } = await Api.organization.listUserGroups(userPortalStore.user.tenant_id);

    if (userGroups) {
      await async.forEachLimit(userGroups, 5, async (group) => {
        const members = await Api.member.listOrganizationMembers(userPortalStore.user.tenant_name, {
          state: [Member.State.Active],
          user_group_id: group.id
        });

        group.members = members;
      });

      status.data = userGroups;
      status.filteredData = userGroups;
    }
    status.loading = false;
  };

  useEffect(() => {
    fetchUserGroups();
  }, []);

  const searchGroups = () => {
    status.filteredData = status.data.filter((v) => v.name.includes(status.search.name));
  };

  return (
    <PageHeader style={{ backgroundColor: '#fff' }}>
      <AtomTable
        rowKey="id"
        columns={columns}
        dataSource={status.filteredData}
        bordered={false}
        pagination={{
          total: status.pager.total,
          pageSize: status.pager.pageSize,
          showSizeChanger: true,
          onChange: async (page: number, pageSize: number) => {
            status.pager.page = page;
            status.pager.pageSize = pageSize;
            // await fetchMembers();
          }
        }}
        loading={status.loading}
        onReload={() => fetchUserGroups()}
        actions={() => (
          <>
            <Input
              onChange={(e) => (status.search.name = e.target.value)}
              onPressEnter={() => searchGroups()}
              suffix={<SearchOutlined onClick={() => searchGroups()}></SearchOutlined>}
              placeholder="请输入组名称"
            ></Input>
          </>
        )}
        operations={({ selectedRows }) => (
          <>
            <Button type="primary" onClick={() => newGroup()}>
              新建用户组
            </Button>
            <Popconfirm
              title="确认要删除选中的用户组吗？"
              onConfirm={async () => {
                await forEach(selectedRows, async (row) => {
                  await deleteGroup(row);
                });
              }}
            >
              <Button disabled={!selectedRows.length}>删除</Button>
            </Popconfirm>
          </>
        )}
      ></AtomTable>

      <EditGroupDialog
        title={status.groupDialog.title}
        open={status.groupDialog.open}
        onCancel={() => (status.groupDialog.open = false)}
        onSubmit={async (values, form) => {
          if (status.groupDialog.type === 'new') {
            await Api.organization.createUserGroup(userPortalStore.user.tenant_id, values);
          } else {
            await Api.organization.updateUserGroup(userPortalStore.user.tenant_id, status.groupDialog.group.id, values);
          }
          status.groupDialog.open = false;
          await fetchUserGroups();
          userPortalStore.fetchApplications();

          form.resetFields();
        }}
        destroyOnClose
        values={status.groupDialog.group}
      ></EditGroupDialog>

      <PermissionDialog
        title={`权限管理(${status.permissionDialog.group.name})`}
        open={status.permissionDialog.open}
        onCancel={() => (status.permissionDialog.open = false)}
        onSubmit={(values) => {
          merge(status.permissionDialog.group.services, values);

          Api.organization
            .updateUserGroupPermissions(
              userPortalStore.user.tenant_id,
              status.permissionDialog.group.id,
              status.permissionDialog.group
            )
            .then(() => {
              userPortalStore.fetchApplications();
            })
            .finally(() => {
              status.permissionDialog.open = false;
            });
        }}
        values={toJS(status.permissionDialog.group.services)}
        destroyOnClose
      ></PermissionDialog>

      <MemberDialog
        title="成员管理"
        open={status.memberDialog.open}
        onCancel={() => (status.memberDialog.open = false)}
        dataSource={userPortalStore.org.members?.map((v) => {
          return {
            key: v.id,
            title: v.name,
            description: v.name,
            chosen: !!find(status.memberDialog.group.members, (a) => a.id === v.id)
          };
        })}
        onSubmit={async (values) => {
          await Api.organization
            .updateUserGroupMembers(userPortalStore.user.tenant_id, status.memberDialog.group.id, { members: values })
            .then(() => {
              userPortalStore.fetchApplications();
            })
            .finally(() => {
              status.memberDialog.open = false;
            });
          fetchUserGroups();
        }}
        titles={['单位成员', status.memberDialog.group.name]}
      ></MemberDialog>
    </PageHeader>
  );
});

export default OrgGroup;
