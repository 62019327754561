import { Form, Modal, ModalProps, Select } from "antd";
import Api from "api/Api";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { userPortalStore } from "src/stores/userPortal";
import { Service } from "types/service";


const ApplyServiceDialog: React.FC<ModalProps & { onSubmit: (args: any) => void }> = observer(({
  onOk, onSubmit, ...rest
}) => {
  const [services, setServices] = useState<Service.AsObject[]>([]);
  const [form] = Form.useForm();

  const fetchServices = async () => {
    const res = await Api.service.listOrganizationServices(userPortalStore.org.name, { i_am_manager: true })
    setServices(res);
  }

  useEffect(() => {
    rest.open && fetchServices();
  }, [rest.open])

  return (
    <Modal
      width="40vw"
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      title="开通新应用"
      maskClosable={false}

      onOk={async () => {
        try {
          const values = await form.validateFields();
          onSubmit(values);
        } catch (err) {
        }
      }}
      {...rest}
    >
      <Form form={form}>
        <Form.Item label="应用" name="name" rules={[{ required: true }]}>
          <Select
            allowClear
            showSearch
            filterOption={(input, option) => {
              if (option && option.title) {
                return option.title === input || option.title.indexOf(input) !== -1
              } else {
                return true
              }
            }}
          >
            {userPortalStore.applications.map((app) => (
              <Select.Option
                disabled={!!services.find((i) => i.name === app.name)}
                key={app.id}
                title={app.name}
                value={app.name}
              >
                {app.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
})

export default ApplyServiceDialog;
