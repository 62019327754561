import React from 'react'
import { useEffect } from 'react'
import moment from 'moment'
import { PageHeader, Card, Button, Table, Input, Modal, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Api from '../../../api/Api'
import { User } from 'types/user'
import { useStore } from 'utils/stateStore'
import { TranRole } from 'utils/constants'
import { useNavigate } from 'react-router'

class Status {
  users: User.AsObject[] = []
  total: number = undefined
  page = 1
  page_size = 10
  isLoading = false
  curUserId: number = undefined
  nameFilter: string = undefined
}

export const UserManage: React.FC<any> = () => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()

  const fetchUsers = async () => {
    status.isLoading = true
    try {
      const listUsersRes = await Api.user.listUsers({
        name_pattern: status.nameFilter,
        page_number: status.page - 1,
        page_size: status.page_size
      })
      status.users = listUsersRes.users
      status.total = listUsersRes.page.total
    } finally {
      status.isLoading = false
    }
  }

  const loadData = async () => {
    await fetchUsers()
  }

  useEffect(() => {
    loadData()
  }, [])

  const columns: ColumnsType<User.AsObject> = [
    {
      title: '用户账号',
      dataIndex: 'name'
    },
    {
      title: '用户名称',
      dataIndex: 'fullname'
    },
    {
      title: '邮箱',
      dataIndex: 'email'
    },
    {
      title: '角色',
      render(value, record) {
        return record.system_roles?.map((i) => (
          <Tag key={i} color="blue">
            {TranRole[i]}
          </Tag>
        ))
      }
    },
    {
      title: '状态',
      width: 80,
      dataIndex: 'state',
      align: 'center',
      render(value) {
        switch (value) {
          case User.UserState.Active:
            return '正常'
          case User.UserState.Freezed:
            return '冻结'
          default:
            return ''
        }
      }
    },
    {
      title: '创建日期',
      dataIndex: 'created_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '最后一次登录',
      dataIndex: 'last_login_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      width: 160,
      fixed: 'right',
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Button
              size="small"
              type="link"
              onClick={() => {
                navigate(`/admin_portal/user_manage/${record.name}/update`)
              }}
            >
              编辑
            </Button>
            {record.state === User.UserState.Freezed && (
              <Button
                size="small"
                type="link"
                onClick={() => {
                  enableUser(record)
                }}
              >
                激活
              </Button>
            )}
            {record.state === User.UserState.Active && (
              <Button
                size="small"
                type="link"
                onClick={() => {
                  disableUser(record)
                }}
              >
                冻结
              </Button>
            )}
          </div>
        )
      }
    }
  ]

  const disableUser = (record: User.AsObject) => {
    Modal.confirm({
      content: '确认要冻结选中的用户么？',
      onOk: async () => {
        await Api.user.disableUser(record.name)
        await fetchUsers()
      }
    })
  }

  const enableUser = (record: User.AsObject) => {
    Modal.confirm({
      content: '确认要激活选中的用户么？',
      onOk: async () => {
        await Api.user.enableUser(record.name)
        await fetchUsers()
      }
    })
  }

  return (
    <PageHeader title="用户管理" style={{ background: '#FFF' }}>
      <Card>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {/* <Button
            type="primary"
            onClick={() => {
              navigate('/admin_portal/user_manage/create')
            }}
          >
            新建
          </Button> */}
          <Input.Search
            placeholder="请输入用户名"
            value={status.nameFilter}
            enterButton="搜索"
            style={{ width: 300 }}
            onChange={(ev) => {
              status.nameFilter = ev.target.value
              status.page = 1
            }}
            onSearch={fetchUsers}
          />
        </div>
        <Table<User.AsObject>
          rowKey="id"
          style={{
            marginTop: 20
          }}
          scroll={{ x: 1580 }}
          columns={columns}
          bordered={true}
          dataSource={status.users}
          pagination={{
            total: status.total,
            pageSize: status.page_size,
            showSizeChanger: false,
            onChange: async (page: number, page_size: number) => {
              status.page = page
              status.page_size = page_size
              await fetchUsers()
            }
          }}
        />
      </Card>
    </PageHeader>
  )
}
