import React from 'react'
import { useEffect } from 'react'
import { Card, PageHeader, Form, Input, Button, Checkbox } from 'antd'
import Api from 'api/Api'
import { useStore } from 'utils/stateStore'
import { useNavigate } from 'react-router'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 }
}

class Status {
  name: string = undefined
  perms: { id: number; name: string; permissions: any }[] = []
  curPerms: number[] = []
  isLoading = false
}

export const CreateRole: React.FC<any> = () => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()

  const fetchPermission = async () => {
    const getPermsRes = await Api.role.getPermissions()
    status.perms = getPermsRes.record
  }

  const createUser = async () => {
    status.isLoading = true
    const createRes = await Api.role.createRole({
      name: status.name,
      perms_set_ids: status.curPerms
    })
    status.isLoading = false
    if (createRes.code === 200) {
      navigate(-1)
    }
  }

  useEffect(() => {
    fetchPermission()
  }, [])

  const onChange = (val) => {
    status.curPerms = val
  }

  return (
    <PageHeader
      title="新建角色"
      style={{ background: '#fff' }}
      onBack={() => {
        navigate(-1)
      }}
    >
      <Card>
        <Form {...layout}>
          <Form.Item label={<span className="label-required">角色名称</span>}>
            <Input
              placeholder="请输入角色名称"
              value={status.name}
              onChange={(event) => {
                status.name = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label="权限">
            <Checkbox.Group onChange={onChange}>
              {status.perms?.map((item) => (
                <Checkbox key={item.id} value={item.id}>
                  {item.name}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  navigate(-1)
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                loading={status.isLoading}
                style={{ marginLeft: 10 }}
                disabled={!status.name}
                onClick={createUser}
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </PageHeader>
  )
}
