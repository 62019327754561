import React from 'react'
import { useEffect } from 'react'
import { PageHeader, Card, Form, Input, Button, Checkbox, message } from 'antd'
import Api from 'api/Api'
import { useStore } from 'utils/stateStore'
import { useNavigate, useParams } from 'react-router'

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 18
  }
}

class Status {
  name: string = undefined || '管理员'
  curPerms: number[] = []
  isLoading = false
  perms: { id: number; name: string; permissions: any }[] = []
}

export const UpdateRole: React.FC = (props) => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()
  const params = useParams()

  const fetchPermissions = async () => {
    const getPermsRes = await Api.role.getPermissions()
    status.perms = getPermsRes.record
  }

  const fetchRole = async () => {
    const roleId = params.id
    const getRole = await Api.role.getRole(+roleId)
    status.name = getRole.record.name
    status.curPerms = getRole.record.perms_set_ids
  }

  const updateRole = async () => {
    status.isLoading = true
    const updateRes = await Api.role.updateRole(+params.id, {
      name: status.name,
      perms_set_ids: status.curPerms
    })
    status.isLoading = false
    if (updateRes.code === 200) {
      navigate(-1)
    } else {
      message.error(updateRes.err_msg)
    }
  }

  const onChange = (val) => {
    status.curPerms = val
  }

  useEffect(() => {
    fetchPermissions()
    fetchRole()
  }, [])

  return (
    <PageHeader
      title="编辑角色"
      style={{
        background: '#fff'
      }}
      onBack={() => {
        navigate(-1)
      }}
    >
      <Card>
        <Form {...layout}>
          <Form.Item label={<span className="label-required">角色名</span>}>
            <Input
              placeholder="请输入角色名"
              value={status.name}
              onChange={(event) => {
                status.name = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label="权限">
            <Checkbox.Group value={status.curPerms} onChange={onChange}>
              {status.perms?.map((item) => (
                <Checkbox key={item.id} value={item.id}>
                  {item.name}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <Button
                onClick={() => {
                  navigate(-1)
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: 20
                }}
                loading={status.isLoading}
                disabled={!status.name}
                onClick={updateRole}
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </PageHeader>
  )
}
