import { Member } from '../types/member';
import axios from '../utils/axios';

export const inviteOrganizationMember = (params: {
  org_name: string;
  member: Member.AsObject;
}): Promise<Member.AsObject> => {
  return axios.post(`/api/v1/organizations/${params.org_name}/members`, params.member);
};

export const listOrganizationMembers = (
  org_name: string,
  params?: { state?: Member.State[]; user_group_id?: string }
): Promise<Member.AsObject[]> => {
  return axios.get(`/api/v1/organizations/${org_name}/members`, {
    params
  });
};

export const removeOrganizationMember = (params: { org_name: string; member_name: string }): Promise<void> => {
  return axios.delete(`/api/v1/organizations/${params.org_name}/members/${params.member_name}`);
};

export const updateOrganizationMember = (
  org_name: string,
  member_name: string,
  params: {
    state: Member.State;
  }
): Promise<Member.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}/members/${member_name}`, params);
};

export const getMember = (org_name: string, member_id: string) => {
  return axios.get<any, Member.AsObject>(`/api/v2/organizations/${org_name}/members/${member_id}`);
};
