export enum Permission {
  RoleDel = '删除角色',
  OrgApp = '单位业务授权',
  OrgApply = '单位审批',
  OrgSearch = '单位查询',
  OrgList = '单位申请列表',
  RoleAdd = '新增角色',
  OrgAdd = '新建单位',
  Login = '模拟登录',
  UserFree = '用户冻结',
  UserAdd = '用户新增',
  UserSearch = '用户查询',
  UserEdit = '用户编辑',
  OrgEdit = '编辑单位信息',
  RoleSearch = '角色查询',
  RoleEdit = '角色编辑'
}

export enum Role {
  UserAdmin = 'user-admin',
  OrganizationAdmin = 'organization-admin',
  ImpersonationAdmin = 'impersonation-admin'
}

export enum TranRole {
  'user-admin' = '用户管理',
  'organization-admin' = '单位管理',
  'impersonation-admin' = '模拟登录'
}

export enum AppName {
  'DataOcean' = '数据管理',
  // 'Hubble' = '数据管理',
  'LabelX' = '数据标注',
  'ModelShop' = '模型管理',
  'TestStand' = '服务管理',
  La = '数据标注',
  Hubble = '数据管理',
  Ti = '模型训练',
  Mo = '模型管理',
  Idp = '服务管理'
}
