import axios from '../utils/axios';
import { Organization } from '../types/organization';
import { UserGroup } from 'types/userGroup';

export interface IListOrganizationsReq {
  name_pattern?: string;
  i_am_creator?: boolean;
  i_am_manager?: boolean;
  i_am_member?: boolean;
  has_service?: string;
  state?: Organization.State[];
  page_number: number;
  page_size: number;
}

export interface IListOrganizationsResp {
  page: { number: number; size: number; total: number };
  organizations: Organization.AsObject[];
}

export interface IUpdateOrganizationResp {
  code: number;
  data: Organization.AsObject;
  msg: string;
}

export interface ICreateOrganizationResp {
  code: number;
  data: Organization.AsObject;
  msg: string;
}

export interface IGetOrganizationResp {
  code: number;
  data: Organization.AsObject;
  msg: string;
}

export interface IUpdateOrganizationRep {
  managers?: string[];
  type?: string;
  contact?: string;
  telephone?: string;
  address?: string;
  uscid?: string;
}

export interface ICreateOrganizationReq {
  name: string;
  type: string;
  managers: string[];
  telephone: string;
  address: string;
  uscid: string;
  contact: string;
  state: Organization.State;
}

export const createOrganization = (params: ICreateOrganizationReq): Promise<Organization.AsObject> => {
  return axios.post('/api/v1/organizations', params);
};

export const getOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.get(`/api/v1/organizations/${org_name}`);
};

export const listOrganizations = (params: IListOrganizationsReq): Promise<IListOrganizationsResp> => {
  return axios.get('/api/v1/organizations', { params });
};

export const updateOrganization = (
  org_name: string,
  params: IUpdateOrganizationRep
): Promise<IUpdateOrganizationResp> => {
  return axios.put(`/api/v1/organizations/${org_name}`, params);
};

export const frozenOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}`, { state: Organization.State.Freezed });
};

export const unfrozenOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}`, { state: Organization.State.Active });
};

export const approvalOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}`, { state: Organization.State.Active });
};

export const refuseOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}`, { state: Organization.State.Rejected });
};

export const lockOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}`, { state: Organization.State.Locked });
};

export const unLockOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}`, { state: Organization.State.Active });
};

export const cancelOrganization = (org_name: string): Promise<Organization.AsObject> => {
  return axios.put(`/api/v1/organizations/${org_name}`, { state: Organization.State.Revoked });
};

export const getOrganizationGroups = (org_name: string) => {
  return axios.get<any, {}>(`/api/v1/organizations/${org_name}/user_groups`);
};

export const createUserGroup = (org_id: string, data: Partial<UserGroup.AsObject>) => {
  return axios.post(`/api/v2/organizations/${org_id}/user_groups`, data);
};

export const updateUserGroup = (org_id: string, user_group_id: string, data: Partial<UserGroup.AsObject>) => {
  return axios.put(`/api/v2/organizations/${org_id}/user_groups/${user_group_id}`, data);
};

export const deleteUserGroup = (org_id: string, user_group_id: string) => {
  return axios.delete(`/api/v2/organizations/${org_id}/user_groups/${user_group_id}`);
};

export const listUserGroupMembers = (org_id: string, user_group_id: string) => {
  return axios.get(`/api/v2/organizations/${org_id}/user_groups/${user_group_id}/members`);
};

export const updateUserGroupMembers = (
  org_id: string,
  user_group_id: string,
  data: Pick<UserGroup.AsObject, 'members'>
) => {
  return axios.put(`/api/v2/organizations/${org_id}/user_groups/${user_group_id}/members`, data);
};

export const updateUserGroupPermissions = (
  org_id: string,
  user_group_id: string,
  data: Partial<UserGroup.AsObject>
) => {
  return axios.put(`/api/v2/organizations/${org_id}/user_groups/${user_group_id}/permissions`, data);
};

export const listUserGroups = (
  org_id: string,
  params?: Partial<{
    name_pattern: string;
    state: UserGroup.State;
    page_number: number;
    page_size: number;
  }>
) => {
  return axios.get<any, { user_groups?: UserGroup.AsObject[] }>(`/api/v2/organizations/${org_id}/user_groups`, {
    params
  });
};

export const updateMemberGroups = (
  org_id: string,
  member_id: string,
  data?: {
    user_groups: string[];
  }
) => {
  return axios.put<any, { user_groups?: UserGroup.AsObject[] }>(
    `/api/v2/organizations/${org_id}/members/${member_id}/user_group`,
    data
  );
};
