import { Invitation } from '../types/invitation';
import axios from '../utils/axios';

export const createInvitation = (params: { invitee: string; organization: string }): Promise<void> => {
  return axios.post(`/api/v1/invitations`, params)
}

export const listInvitations = (params: {
  organization?: string
  page_number: number
  page_size: number
  invitee?: string
  state: string
  inviter?: string
}): Promise<{ invitations: Invitation.AsObject[]; page: { size: number; total: number; number: number } }> => {
  return axios.get(`/api/v1/invitations`, { params })
}

export const updateInvitation = (invitation_id: string, params: Invitation.AsObject): Promise<Invitation.AsObject> => {
  return axios.put(`/api/v1/invitations/${invitation_id}`, params)
}

export const acceptInvitation = (invitation_id: string): Promise<Invitation.AsObject> => {
  return axios.put(`/api/v1/invitations/${invitation_id}`, { state: Invitation.State.Accepted })
}

export const rejectInvitation = (invitation_id: string): Promise<Invitation.AsObject> => {
  return axios.put(`/api/v1/invitations/${invitation_id}`, { state: Invitation.State.Rejected })
}

export const listInvitationJournals = (params: {
  organization: string
  invitee?: string
  inviter?: string
}): Promise<{ invitations: Invitation.AsObject[]; page: { size: number; total: number; number: number } }> => {
  return axios.get('/api/v1/journals/invitations', { params })
}
