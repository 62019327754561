export namespace Service {
  export enum State {
    Active = 'active',
    Freezed = 'freezed',
    Locked = 'locked',
    Applying = 'applying',
    Rejected = 'rejected'
  }

  export enum ServiceRequestState {
    Applying = 'applying',
    Accepted = 'accepted',
    Rejected = 'rejected'
  }

  export interface AsObject {
    name: string
    login: string
    website: string
    organization: string
    created_at: Date
    updated_at: Date
    state: State
    last_login_at: Date
  }
  export interface ServiceRequest {
    organization: string
    application: string
    creator: string
    state: ServiceRequestState
    created_at: Date
    updated_at: Date
  }
}
