import { ReloadOutlined } from '@ant-design/icons';
import { Button, Row, Space, Table, TableProps } from 'antd';
import React, { createContext, useContext, useState } from 'react';

type IAtomTableProps<RecordType> = {
  onReload?: () => void;
  actions?: (context: AtomTableContextType<RecordType>) => React.ReactNode;
  operations?: (context: AtomTableContextType<RecordType>) => React.ReactNode;
  header?: boolean;
} & TableProps<RecordType>;

type AtomTableContextType<RecordType> = {
  selectedRows: RecordType[];
};

export const AtomTableContext = createContext<AtomTableContextType<any>>({ selectedRows: [] });

export const useAtomTableContext = () => {
  return useContext(AtomTableContext);
};

const AtomTable = <RecordType extends object = any>(props: IAtomTableProps<RecordType>) => {
  const { onReload, actions, operations, header = true, ...tableProps } = props;
  const { dataSource, loading } = tableProps;
  const [selectedRows, setSelectedRows] = useState<RecordType[]>([]);

  return (
    <AtomTableContext.Provider value={{ selectedRows }}>
      <div className="atom-table">
        {header && (
          <Row style={{ color: '#798295', marginBottom: 10 }} align={'middle'} justify={'space-between'}>
            <Space>
              {operations && operations({ selectedRows })}
              <Button
                loading={loading}
                onClick={onReload}
                style={{ color: '#798295' }}
                icon={<ReloadOutlined></ReloadOutlined>}
              ></Button>

              <span>共{dataSource?.length}条记录</span>

              {/* <Checkbox style={{ color: '#798295' }}>全选</Checkbox> */}
            </Space>
            <Space>{actions && actions({ selectedRows })}</Space>
          </Row>
        )}

        <Table
          scroll={{ x: true }}
          rowSelection={{
            onChange(selectedRowKeys, selectedRows, info) {
              setSelectedRows(selectedRows);
            }
          }}
          {...tableProps}
        ></Table>
      </div>
    </AtomTableContext.Provider>
  );
};

export default AtomTable;
