import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Message } from 'types/chat';
import Markdown from './Markdown';
import icon1 from 'src/assets/service/icon-a.png';
import icon2 from 'src/assets/service/icon-u.png';

type IChatMessageProps = {
  message: Message;
};

const ChatMessage: React.FC<IChatMessageProps> = ({ message }) => {
  return (
    <div className={classNames(['chat-message', message.role])}>
      <div className="inner">
        <div className="avatar">
          <img src={message.role === 'assistant' ? icon1 : icon2} alt="" />
        </div>
        <div style={{ flex: 1 }}>
          <div className="content">
            <Markdown content={message.content}></Markdown>
          </div>
          {/* <div className="date" style={{ textAlign: 'right' }}>
            2023-09-12
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default observer(ChatMessage);
