import { Badge, Button, Popconfirm, Table, Tabs, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Api from 'api/Api';
import { logout } from 'api/user.api';
import async from 'async';
import EditOrgDialog from 'components/EditOrgDialog';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect } from 'react';
import Pager from 'src/stores/pager';
import { userPortalStore } from 'src/stores/userPortal';
import { Invitation } from 'types/invitation';
import { Organization } from 'types/organization';
import { login } from 'utils/index';

class Status {
  orgs = {
    data: [] as Organization.AsObject[],
    total: 0,
    pager: new Pager()
  };

  invitations = {
    data: [] as Invitation.AsObject[],
    total: 0,
    pager: new Pager()
  };

  createOrgDialogOpen = false;

  createOrgDialogLoading = false;

  constructor() {
    makeAutoObservable(this);
  }
}

const status = new Status();
const { orgs, invitations } = status;

const OrgList = observer(() => {
  const fetchOrgs = async () => {
    const data = await Api.organization.listOrganizations({
      i_am_member: true,
      state: [Organization.State.Active, Organization.State.Freezed, Organization.State.Locked],
      page_number: orgs.pager.page - 1,
      page_size: orgs.pager.pageSize
    });

    await async.forEachLimit(data.organizations, 6, async (org) => {
      const data = await Api.organization.listUserGroups(org.id);
      // @ts-ignore
      org.user_groups = data.user_groups;
    });

    orgs.data = data.organizations;
    orgs.total = data.page.total;
  };

  const fetchInvitations = async () => {
    const data = await Api.invitation.listInvitations({
      invitee: userPortalStore.user?.name,
      page_number: 0,
      page_size: 10000,
      state: Invitation.State.Pending
    });

    invitations.data = data.invitations;
    invitations.total = data.page.total;
  };

  useEffect(() => {
    fetchOrgs();
    fetchInvitations();
  }, []);

  const orgColumns: ColumnProps<Organization.AsObject>[] = [
    {
      title: '单位名称',
      ellipsis: true,
      dataIndex: 'name',
      width: 250
    },
    {
      title: '角色',
      width: 100,
      render(value, record) {
        return record?.managers?.includes(userPortalStore.user?.name!) ? '管理员' : '成员';
      }
    },
    {
      title: '用户组',
      width: 300,
      dataIndex: 'user_groups',
      render(value, record, index) {
        return value?.map((v: any, idx: number) => (
          <Tag
            style={{
              background: '#F4F7FE',
              border: 0,
              padding: '4px 12px'
            }}
            key={idx}
          >
            {v.name}
          </Tag>
        ));
      }
    },
    {
      title: '单位状态',
      dataIndex: 'state',
      width: 80,
      render(value) {
        switch (value) {
          case Organization.State.Active:
            return '正常';
          case Organization.State.Freezed:
            return '冻结';
          case Organization.State.Applying:
            return '待审';
          case Organization.State.Locked:
            return '锁定';
          case Organization.State.Rejected:
            return '拒绝';
          case Organization.State.Revoked:
            return '撤回';
          default:
            return '';
        }
      }
    },
    {
      title: '成员',
      align: 'center',
      width: 100,
      render(value, record) {
        return record?.member_count;
      }
    },
    {
      title: '创建日期',
      dataIndex: 'created_at',
      width: 180,
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return '';
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      title: '操作',
      width: 100,
      render(value, record) {
        return (
          <>
            {record.state === Organization.State.Active && (
              <a
                onClick={async () => {
                  await logout();
                  login(record.name);
                }}
              >
                进入单位
              </a>
            )}
          </>
        );
      }
    }
  ];

  const invitationColumns: ColumnProps<Invitation.AsObject>[] = [
    {
      title: '单位名称',
      ellipsis: true,
      dataIndex: 'organization'
    },
    {
      title: '邀请人',
      align: 'center',
      dataIndex: 'inviter'
    },
    {
      title: '邀请日期',
      align: 'center',
      dataIndex: 'updated_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return '';
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      title: '操作',
      width: 180,
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Popconfirm
              title="确认要同意加入这个单位么？"
              onConfirm={async () => {
                await Api.invitation.acceptInvitation(record.id);
                await fetchInvitations();
                await userPortalStore.fetchInvitations();
              }}
            >
              <Button type="link" size="small">
                同意
              </Button>
            </Popconfirm>
            <Popconfirm
              title="确认要拒绝加入这个单位么？"
              onConfirm={async () => {
                await Api.invitation.rejectInvitation(record.id);
                await fetchInvitations();
                await userPortalStore.fetchInvitations();
              }}
            >
              <Button type="link" size="small">
                拒绝
              </Button>
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  const items = [
    {
      label: <span>单位列表</span>,
      key: '1',
      children: (
        <Table
          className="atom-table"
          size="small"
          rowKey="id"
          columns={orgColumns}
          dataSource={orgs.data}
          bordered={false}
          pagination={{
            total: orgs.total,
            pageSize: orgs.pager.pageSize,
            showSizeChanger: true,
            onChange: async (page: number, pageSize: number) => {
              orgs.pager.page = page;
              orgs.pager.pageSize = pageSize;
              await fetchOrgs();
            }
          }}
        ></Table>
      )
    },
    {
      label: (
        <Badge count={invitations.total}>
          <span>单位邀请</span>
        </Badge>
      ),
      key: '2',
      children: (
        <Table
          rowKey="id"
          columns={invitationColumns}
          dataSource={invitations.data}
          bordered={false}
          pagination={{
            total: invitations.total,
            pageSize: invitations.pager.pageSize,
            showSizeChanger: true,
            onChange: async (page: number, pageSize: number) => {
              invitations.pager.page = page;
              invitations.pager.pageSize = pageSize;
              await fetchInvitations();
            }
          }}
        ></Table>
      )
    }
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Button
        onClick={() => {
          status.createOrgDialogOpen = true;
        }}
        className="atom-btn"
        type="primary"
        style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
      >
        创建新单位
      </Button>
      <Tabs type="card" items={items} className="atom-tabs"></Tabs>

      <EditOrgDialog
        title="创建新单位"
        width={900}
        open={status.createOrgDialogOpen}
        onCancel={() => (status.createOrgDialogOpen = false)}
        loading={status.createOrgDialogLoading}
        onFinish={async (values, form) => {
          status.createOrgDialogLoading = true;
          try {
            await Api.organization.createOrganization({
              ...values,
              state: Organization.State.Active
            });
            form.resetFields();
            status.createOrgDialogLoading = false;
            status.createOrgDialogOpen = false;
          } catch (err) {
            status.createOrgDialogLoading = false;
          }

          fetchOrgs();
        }}
        fields={{ managers: [userPortalStore.user.name] }}
      ></EditOrgDialog>
    </div>
  );
});

export default OrgList;
