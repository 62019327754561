import { Form, Input, Modal, ModalProps } from 'antd';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

const EditGroupDialog: React.FC<ModalProps & { onSubmit: (...args: any[]) => void; values?: Object }> = observer(
  ({ onOk, onSubmit, values, ...rest }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
      if (values) {
        form.setFieldsValue(values);
      }
    }, [values, form]);

    return (
      <Modal
        width="40vw"
        bodyStyle={{
          maxHeight: '70vh',
          overflowY: 'auto'
        }}
        maskClosable={false}
        onOk={async () => {
          if (await form.validateFields()) {
            onSubmit(form.getFieldsValue(), form);
          }
        }}
        {...rest}
      >
        <Form form={form}>
          <Form.Item label="用户组名称" name={'name'} required rules={[{ required: true }]}>
            <Input></Input>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default EditGroupDialog;
