import { Application } from '../types/application';
import axios from '../utils/axios';

export const getApplication = (app_name: string): Promise<Application.AsObject> => {
  return axios.get(`/api/v1/applications/${app_name}`)
}

export const listApplications = (): Promise<Application.AsObject[]> => {
  return axios.get('/api/v1/applications')
}
