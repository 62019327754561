import { SendOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Model } from 'types/chat';

type IChatInputProps = {
  style?: React.CSSProperties;
  onSend: (value: string) => void;
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
  model: string;
  models: Model[];
  onModelChange: (value: string) => void;
};

const ChatInput: React.FC<IChatInputProps> = ({
  style,
  onSend,
  onChange: onChangeProp,
  disabled,
  value: valueProp,
  models,
  model,
  onModelChange
}) => {
  const [valueState, setValue] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const value = valueProp !== undefined ? valueProp : valueState;

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (valueProp !== undefined) {
      onChangeProp(value);
    } else {
      setValue(value);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (isTyping) {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!disabled) {
        onSend(value);
      }
    }
  };

  // useEffect(() => {
  //   if (textareaRef && textareaRef.current) {
  //     textareaRef.current.style.height = 'inherit';
  //     textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
  //     textareaRef.current.style.overflow = `${textareaRef?.current?.scrollHeight > 400 ? 'auto' : 'hidden'}`;
  //   }
  // }, [value]);

  return (
    <div className="chat-input" style={style}>
      <div style={{ position: 'absolute', float: 'left' }}>
        <Select
          value={model}
          options={models.map((v) => ({
            value: v.id,
            label: v.id
          }))}
          style={{ position: 'absolute', left: 0, bottom: 10, width: 150 }}
          onChange={(v) => {
            onModelChange(v);
          }}
        ></Select>
      </div>

      {/* <Button
        disabled={disabled}
        className="btn-chose"
        type="text"
        style={{ padding: '2px 6px', height: 'auto' }}
        onClick={() => onSend(value)}
      >
        <SendOutlined></SendOutlined>
      </Button> */}
      <textarea
        ref={textareaRef}
        style={{
          bottom: `${textareaRef?.current?.scrollHeight}px`,
          maxHeight: 400,
          overflow: `${textareaRef.current && textareaRef.current.scrollHeight > 400 ? 'auto' : 'hidden'}`
        }}
        placeholder={'请在这里提问'}
        value={value}
        rows={1}
        onCompositionStart={() => setIsTyping(true)}
        onCompositionEnd={() => setIsTyping(false)}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <Button
        disabled={disabled}
        className="btn-send"
        type="text"
        style={{ padding: '2px 6px', height: 'auto' }}
        onClick={() => onSend(value)}
      >
        <SendOutlined></SendOutlined>
      </Button>
    </div>
  );
};

export default observer(ChatInput);
