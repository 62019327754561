import { Alert, Button, Col, PageHeader, Row } from 'antd';
import { Gis, H, I, La, Mo, Ti } from 'components/Apps';
import Section from 'components/Section';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';

const Welcome = observer(() => {
  const navigate = useNavigate();

  return (
    <PageHeader style={{ backgroundColor: '#fff', minHeight: '100%', marginTop: 20 }}>
      <h2 style={{ fontSize: 36 }}>欢迎使用 ATOM！</h2>

      <Section title={'教程信息'} backgroundColor={null}>
        <p>
          用户可以进行
          <Button
            onClick={() => navigate('/user_portal/orgs')}
            style={{ color: '#1890ff', borderColor: '#1890ff', margin: '0 10px' }}
          >
            创建单位
          </Button>{' '}
          或者
          <Button
            style={{ color: '#1890ff', borderColor: '#1890ff', margin: '0 10px' }}
            onClick={() => navigate('/user_portal/orgs')}
          >
            加入单位
          </Button>
          (创建单位教程 &gt;, 加入单位教程 &gt; ) 在创建/加入单位后，您将可以使用以下功能：
        </p>

        <div style={{ backgroundColor: 'rgba(30,102,235,0.05)', padding: 20 }}>
          <Row justify={'space-between'} wrap>
            <Row justify={'space-between'} style={{ padding: 10, width: '48%', borderBottom: '1px dashed #ccc' }}>
              <La></La>
              <Link target="_blank" to="https://atom.supremind.com/hc/label-x">
                点击进入教程 &gt;
              </Link>
            </Row>
            <Row justify={'space-between'} style={{ padding: 10, width: '48%', borderBottom: '1px dashed #ccc' }}>
              <Ti></Ti>
              <Link target="_blank" to="https://atom.supremind.com/hc/ti">
                点击进入教程 &gt;
              </Link>
            </Row>
            <Row justify={'space-between'} style={{ padding: 10, width: '48%', borderBottom: '1px dashed #ccc' }}>
              <Mo></Mo>
              <Link target="_blank" to="https://atom.supremind.com/hc/mo">
                点击进入教程 &gt;
              </Link>
            </Row>
            <Row justify={'space-between'} style={{ padding: 10, width: '48%', borderBottom: '1px dashed #ccc' }}>
              <I></I>
              <Link target="_blank" to="https://atom.supremind.com/hc/i">
                点击进入教程 &gt;
              </Link>
            </Row>
            <Row justify={'space-between'} style={{ padding: 10, width: '48%' }}>
              <H></H>
              <Link to="">点击进入教程 &gt;</Link>
            </Row>
            {/* <Row justify={"space-between"} style={{ padding: 10 }}>
                <Gis></Gis>
                <Link to="">点击进入教程 &gt;</Link>
              </Row> */}
          </Row>
          <Alert
            style={{ backgroundColor: '#fff', marginTop: 20 }}
            type="info"
            showIcon
            description={
              <div>
                <p>
                  ATOM
                  工具链平台是一个协作平台，所有用户通过单位/组织的形式组成协作关系，所有的功能和数据都隶属于单位/组织。
                </p>
                <p>
                  同一个用户账号可以同时隶属于不同的单位组织，用户可以通过切换不同的单位/组织使用该单位/组织所开通的功能，同时享有该单位/组织的数据。
                </p>
                <p>
                  更多使用教程详见{' '}
                  <Button type="link" target="_blank" href="https://atom.supremind.com">
                    帮助中心 &gt;
                  </Button>
                </p>
              </div>
            }
          ></Alert>
        </div>
      </Section>
    </PageHeader>
  );
});

export default Welcome;
