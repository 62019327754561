import { UserGroup } from './userGroup';

export namespace Member {
  export enum State {
    Active = 'active',
    Locked = 'locked',
    Left = 'left'
  }
  export interface AsObject {
    // id: string
    // name: string
    // fullname: string
    // email: string
    // system_roles: string[]
    // state: State
    // created_at: Date
    // updated_at: Date
    // last_login_at: Date
    id: string;
    created_at: '2023-09-11T11:03:42.222Z';
    updated_at: '2023-09-11T11:03:42.222Z';
    joined_at: '2023-09-11T11:03:42.222Z';
    last_login_at: '0001-01-01T00:00:00Z';
    organization: {
      id: string;
      created_at: Date;
      updated_at: Date;
      last_login_at: Date;
    };
    // user_groups: string[];
    user_groups: UserGroup.AsObject[];
    roles: ['superuser'];
    state: State;
    user: {
      id: string;
      name: 'admin';
      created_at: '2023-09-11T11:03:42.222Z';
      updated_at: '2023-09-11T11:03:42.222Z';
      last_login_at: '0001-01-01T00:00:00Z';
      fullname: 'admin';
      email: 'admin@supremind.info';
      system_roles: ['user-admin'];
      state: State;
    };
    name: string;
  }
}
