import React from 'react'
import { useEffect } from 'react'
import { Card, PageHeader, Form, Input, Button, Radio, message, Select } from 'antd'
import Api from 'api/Api'
import { useStore } from 'utils/stateStore'
import { User } from 'types/user'
import { Organization } from 'types/organization'
import { useNavigate } from 'react-router'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

class Status {
  name: string = undefined
  type: string = undefined
  managers: string[] = []
  uscid: string = undefined
  contact: string = undefined
  isLoading = false
  telephone: string = undefined
  address: string = undefined
  users: User.AsObject[] = []
}

export const CreateOrg: React.FC<any> = () => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()

  const createOrg = async () => {
    status.isLoading = true
    const reg_tel = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
    if (!!status.telephone && !reg_tel.test(status.telephone)) {
      status.isLoading = false
      return message.error('电话号码不符合规范')
    }
    const reg_socialCode = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
    if (!!status.uscid && !reg_socialCode.test(status.uscid)) {
      status.isLoading = false
      return message.error('统一社会信用代码不符合规范')
    }
    await Api.organization.createOrganization({
      name: status.name,
      type: status.type,
      managers: status.managers,
      telephone: status.telephone,
      address: status.address,
      uscid: status.uscid,
      contact: status.contact,
      state: Organization.State.Active
    })
    status.isLoading = false
    navigate(-1)
  }

  const onRadioChange = (e) => {
    status.type = e.target.value
  }

  const fetchUsers = async () => {
    const listUsersRes = await Api.user.listUsers({
      page_size: 10000
    })
    status.users = listUsersRes.users
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <PageHeader
      title="新建单位"
      style={{ background: '#fff' }}
      onBack={() => {
        navigate(-1)
      }}
    >
      <Card>
        <Form {...layout}>
          <Form.Item label={<span className="label-required">单位名称</span>}>
            <Input
              placeholder="请输入单位名称"
              value={status.name}
              onChange={(event) => {
                status.name = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label={<span className="label-required">客户类型</span>}>
            <Radio.Group onChange={onRadioChange} value={status.type}>
              <Radio value={Organization.Type.Government}>政府用户</Radio>
              <Radio value={Organization.Type.Institution}>企业用户</Radio>
              <Radio value={Organization.Type.Personal}>个人用户</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={<span className="label-required">管理员</span>}>
            <Select
              mode="multiple"
              allowClear
              value={status.managers}
              onChange={(value) => {
                status.managers = value
              }}
            >
              {status.users.map((i) => (
                <Select.Option value={i.name} key={i.name}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="联系人">
            <Input
              placeholder="请输入联系人"
              value={status.contact}
              onChange={(event) => {
                status.contact = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label="联系电话">
            <Input
              placeholder="请输入联系电话"
              value={status.telephone}
              onChange={(event) => {
                status.telephone = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label="通讯地址">
            <Input
              placeholder="请输入通讯地址"
              value={status.address}
              onChange={(event) => {
                status.address = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label="统一社会信用代码">
            <Input
              placeholder="请输入统一社会信用代码"
              value={status.uscid}
              onChange={(event) => {
                status.uscid = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  navigate(-1)
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                loading={status.isLoading}
                style={{ marginLeft: 10 }}
                disabled={!status.name || !status.type || !status.managers.length}
                onClick={createOrg}
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </PageHeader>
  )
}
