export namespace User {
  export enum UserState {
    Active = 'active',
    Freezed = 'freezed'
  }

  export enum SuperUserType {
    InvalidSuperUserType = 0,
    IsSuperUser = 1,
    NotSuperUser = 2
  }

  export interface AsObject {
    id: string
    name: string
    fullname: string
    email: string
    created_at: Date
    updated_at: Date
    last_login_at: Date
    system_roles: string[]
    state: string
  }
}
