import { Col, Row, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ChatUI from 'components/chat/Chat';

import './index.less';
import { makeAutoObservable } from 'mobx';
import { Conversation } from 'types/chat';
import Markdown from 'components/chat/Markdown';

import img1 from 'src/assets/service/1.png';
import img2 from 'src/assets/service/2.png';
import img3 from 'src/assets/service/3.png';

class Status {
  conversations: Conversation[] = [
    {
      id: '1',
      name: '',
      messages: []
    }
  ];

  constructor() {
    makeAutoObservable(this);
  }
}
const status = new Status();

function sectionBackground(url: string): React.CSSProperties {
  return {
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: '40%'
  };
}

const Chat: React.FC = () => {
  const [height, setHeight] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { height } = divRef.current!.getBoundingClientRect();
    setHeight(height - 120);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div>
        <Row gutter={20}>
          <Col span={8}>
            <section className="box1" style={sectionBackground(img1)}>
              <div style={{ width: '70%' }}>
                <h3>语言处理</h3>
                <p style={{ lineHeight: '30px' }}>
                  大模型能够像人类一样处理语言，能够理解更为复杂的问题和上下文，并给出更加精准的答案
                </p>
              </div>
            </section>
          </Col>
          <Col span={8}>
            <section className="box1" style={sectionBackground(img2)}>
              <div style={{ width: '70%' }}>
                <h3>API服务</h3>
                <p style={{ lineHeight: '30px' }}>
                  ATOM提供API服务可以轻松接入您的应用，无需额外的安装和配置，即可快速使用
                </p>
              </div>
            </section>
          </Col>
          <Col span={8}>
            <div className="box1" style={sectionBackground(img3)}>
              <div style={{ width: '70%' }}>
                <h3>私有化部署方案</h3>
                <p style={{ lineHeight: '30px' }}>
                  提供私有化部署方案，确保敏感数据在客户内部安全存储和处理。能够保护知识产权、商业秘密
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ padding: 20, backgroundColor: '#fff', marginTop: 20, flex: 1 }} ref={divRef}>
        <Tabs
          items={[
            {
              key: 'chat',
              label: '在线 demo',
              children: (
                <ChatUI
                  style={{ height }}
                  conversions={status.conversations}
                  apiHost="https://openai-api.yr.supremind.info"
                ></ChatUI>
              )
            },
            {
              key: 'api',
              label: 'API 调用',
              children: (
                <div>
                  <Row>
                    <Col span={24}>
                      <Markdown
                        content={`
## Python SDK

参考: https://github.com/openai/openai-python

~~~
# LLM接口调用(openai接口)示例：
import openai
openai.api_key = ""
openai.api_base = "https://openai-api.yr.supremind.info/v1"

# 指定模型, 目前支持 chatglm2-6b 和 llama-2-7b-chat-hf
model = "chatglm2-6b"

# 开始一段对话
completion = openai.ChatCompletion.create(
  model=model,
  messages=[{"role": "user", "content": "Hello! What is your name?"}]
)
# 输出回答
print(completion.choices[0].message.content)
~~~

## HTTP 接口

参考 https://platform.openai.com/docs/api-reference/completions

~~~
# 列出所有模型
$ curl https://openai-api.yr.supremind.info/v1/models
{"object":"list","data":[{"id":"chatglm2-6b","object":"model","created":1693979196,"owned_by":"fastchat","root":"chatglm2-6b","parent":null,"permission":[{"id":"modelperm-R6o3igqsAx6HDWot99Tue7","object":"model_permission","created":1693979196,"allow_create_engine":false,"allow_sampling":true,"allow_logprobs":true,"allow_search_indices":true,"allow_view":true,"allow_fine_tuning":false,"organization":"*","group":null,"is_blocking":false}]},{"id":"llama-2-7b-chat-hf","object":"model","created":1693979196,"owned_by":"fastchat","root":"llama-2-7b-chat-hf","parent":null,"permission":[{"id":"modelperm-wTyqdsZ9J3wfGBwfcxtidw","object":"model_permission","created":1693979196,"allow_create_engine":false,"allow_sampling":true,"allow_logprobs":true,"allow_search_indices":true,"allow_view":true,"allow_fine_tuning":false,"organization":"*","group":null,"is_blocking":false}]}]}

# 对话
$ curl https://openai-api.yr.supremind.info/v1/chat/completions   -H "Content-Type: application/json"   -H "Authorization: Bearer  "   -d '{
    "model": "chatglm2-6b",
    "messages": [
      {
        "role": "user",
        "content": "Hello!"
      }
    ]
  }'
{"id":"chatcmpl-37V3Buo5B7RgEgyNku729a","object":"chat.completion","created":1693965480,"model":"chatglm2-6b","choices":[{"index":0,"message":{"role":"assistant","content":"Hello! How can I assist you today?"},"finish_reason":"stop"}],"usage":{"prompt_tokens":18,"total_tokens":28,"completion_tokens"

# stream 增量式返回, 设置 stream 标志
$ curl https://openai-api.yr.supremind.info/v1/chat/completions   -H "Content-Type: application/json"   -H "Authorization: Bearer  "   -d '{
    "stream": true,
    "model": "chatglm2-6b",
    "messages": [
      {
        "role": "user",
        "content": "Hello! Please write a demo python script for command line argument parsing"
      }
    ]
  }'
~~~
                  `}
                      ></Markdown>
                    </Col>
                  </Row>
                </div>
              )
            }
          ]}
        ></Tabs>
      </div>
    </div>
  );
};

export default Chat;

export enum ModelId {
  'chatglm2-6b' = 'chatglm2-6b'
}

export const models = {
  [ModelId['chatglm2-6b']]: {
    id: ModelId['chatglm2-6b'],
    name: 'chatglm2-6b'
  }
};
