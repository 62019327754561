import React from 'react';
import { Dropdown, Layout, Menu, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import './AdminPortal.less';
import { useStore } from 'utils/stateStore';
import Api from 'api/Api';
import { Role } from 'utils/constants';
import { User } from 'types/user';

const { Header, Sider, Content } = Layout;

enum SiderMenuKey {
  AccountInfo = 'account_info',
  UserManage = 'user_manage',
  RoleManage = 'role_manage',
  OrgList = 'org_list',
  OrgApply = 'org_apply',
  OrgServiceApply = 'org_service_apply'
}

enum SiderSubMenuKey {
  SystemManage = 'system_manage',
  OrgManage = 'org_manage'
}
class Status {
  selectedKey: string = undefined;
  openKeys: string[] = [];
  userInfo: User.AsObject = undefined;
}

export const AdminPortal = (props: any) => {
  const { status } = useStore(new Status());
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    status.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const pathname = location.pathname;
    let selectedKey = null;
    let openKeys = [];

    if (new RegExp(/^\/admin_portal\/account_info/).test(pathname)) {
      selectedKey = SiderMenuKey.AccountInfo;
      openKeys = [SiderSubMenuKey.SystemManage];
    } else if (new RegExp(/^\/admin_portal\/user_manage/).test(pathname)) {
      selectedKey = SiderMenuKey.UserManage;
      openKeys = [SiderSubMenuKey.SystemManage];
    } else if (new RegExp(/^\/admin_portal\/role_manage/).test(pathname)) {
      selectedKey = SiderMenuKey.RoleManage;
      openKeys = [SiderSubMenuKey.SystemManage];
    } else if (new RegExp(/^\/admin_portal\/org_list/).test(pathname)) {
      selectedKey = SiderMenuKey.OrgList;
      openKeys = [SiderSubMenuKey.OrgManage];
    } else if (new RegExp(/^\/admin_portal\/org_apply/).test(pathname)) {
      selectedKey = SiderMenuKey.OrgApply;
      openKeys = [SiderSubMenuKey.OrgManage];
    } else if (new RegExp(/^\/admin_portal\/org_service_apply/).test(pathname)) {
      selectedKey = SiderMenuKey.OrgServiceApply;
      openKeys = [SiderSubMenuKey.OrgManage];
    }

    status.selectedKey = selectedKey;
    status.openKeys = openKeys;
  }, []);

  const handleSiderClick = (key) => {
    status.selectedKey = key;
    navigate(`/admin_portal/${key}`);
  };

  return (
    <Layout className="admin-portal">
      <Sider className="admin-portal-main-sider">
        <div className="product-name">
          <img src={require('../../assets/atom.svg')} alt="" />
        </div>
        <Menu
          selectedKeys={[status.selectedKey]}
          openKeys={status.openKeys}
          style={{ background: '#FFFFFF' }}
          mode="inline"
          onOpenChange={(openKeys) => {
            status.openKeys = openKeys as string[];
          }}
          onClick={(param) => {
            handleSiderClick(param.key);
          }}
        >
          <React.Fragment>
            <SubMenu key={SiderSubMenuKey.SystemManage} title="系统管理">
              <Menu.Item key={SiderMenuKey.AccountInfo}>账号信息</Menu.Item>
              {status.userInfo?.system_roles?.includes(Role.UserAdmin) && (
                <Menu.Item key={SiderMenuKey.UserManage}>用户管理</Menu.Item>
              )}
              {/* <Menu.Item key={SiderMenuKey.RoleManage}>角色管理</Menu.Item> */}
            </SubMenu>
            {status.userInfo?.system_roles?.includes(Role.OrganizationAdmin) && (
              <SubMenu key={SiderSubMenuKey.OrgManage} title="单位管理">
                <Menu.Item key={SiderMenuKey.OrgList}>单位列表</Menu.Item>
                <Menu.Item key={SiderMenuKey.OrgApply}>单位审批</Menu.Item>
                <Menu.Item key={SiderMenuKey.OrgServiceApply}>单位应用审批</Menu.Item>
              </SubMenu>
            )}
          </React.Fragment>
        </Menu>
      </Sider>
      <Layout className="admin-portal-main">
        <Header className="admin-portal-header">
          <Button
            type="link"
            onClick={() => {
              navigate('/user_portal/');
            }}
          >
            用户控制台
          </Button>
          <Dropdown.Button
            style={{ width: 'auto' }}
            overlay={
              <Menu
                onClick={async (param) => {
                  if (param.key === 'logout') {
                    await Api.user.logout();
                    navigate('/');
                  }
                }}
              >
                <Menu.Item key="logout" icon={<UserOutlined />}>
                  Log Out
                </Menu.Item>
              </Menu>
            }
            icon={<UserOutlined />}
          >
            {JSON.parse(localStorage.getItem('userInfo'))?.fullname || '未登录'}
          </Dropdown.Button>
        </Header>
        <Content className="admin-portal-main-content">{<Outlet></Outlet>}</Content>
      </Layout>
    </Layout>
  );
};
