import { LoadingOutlined } from '@ant-design/icons';
import Api from 'api/Api';
import { IGetUserResp } from 'api/user.api';
import axios from 'axios';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { userPortalStore } from 'src/stores/userPortal';
import { User } from 'types/user';
import { logout } from 'utils/index';

export const AuthContext = createContext<{
  auth?: {
    user?: User.AsObject & IGetUserResp;
  };
  setAuth?: (...arg: any[]) => void;
}>({});

export const useAuth = () => {
  return useContext(AuthContext);
};

type IRequireLoginProps = {
  fallback: React.ReactNode;
} & PropsWithChildren;

const RequireLogin: React.FC<IRequireLoginProps> = ({ children, fallback }) => {
  const [auth, setAuth] = useState<{ user?: User.AsObject & IGetUserResp }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [needLogin, setNeedLogin] = useState(false);

  const loadData = async () => {
    try {
      const userInfo = await Api.user.getUserInfo();
      const user = await Api.user.getUser(userInfo?.username);

      setAuth({
        user: {
          ...userInfo,
          ...user
        }
      });

      userPortalStore.user = {
        ...user,
        ...userInfo
      };

      // for admin portal
      localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, ...user }));
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 401) {
        await logout();

        setNeedLogin(true);
      }
    }
  };

  useEffect(() => {
    loadData().finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {isLoading ? <LoadingOutlined></LoadingOutlined> : needLogin ? fallback : children}
    </AuthContext.Provider>
  );
};

export default RequireLogin;
