import { makeAutoObservable } from "mobx"

export default class Pager {
  constructor(
    public page = 1,
    public pageSize = 10,
    public total = 0,
  ) {
    makeAutoObservable(this)
  }

  set(page: {
    size: number;
    total: number;
    number: number;
  }) {
    this.page = page.number + 1;
    this.pageSize = page.size;
    this.total = page.total;
  }
}