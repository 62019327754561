import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import qs from 'qs';

const axios = Axios.create({
  baseURL: '/'
});

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.method === 'get') {
      //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.data.message) {
      message.error(response.data.message);
    }
    return Promise.resolve(response.data);
  },
  (error: AxiosError) => {
    const data = error.response?.data;

    if (data && data.message) {
      message.error(data.message);
      console.error(data.message);
    }
    return Promise.reject(error);
  }
);

export default axios;
