import { Button, Card, Carousel, Col, Divider, Input, PageHeader, Row, Space, Table, Tabs } from 'antd';
import React from 'react';

import img1 from 'src/assets/service/7.png';
import img2 from 'src/assets/service/2.png';
import img3 from 'src/assets/service/3.png';

const Detection = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true
  };

  return (
    <div style={{ minHeight: '100%' }}>
      <div>
        <Row gutter={20}>
          <Col span={8}>
            <section className="box1">
              <img src={img1} style={{ float: 'right' }} alt="" />
              <div>
                <h3>多目标检测</h3>
                <p>识别动物、植物、商品、建筑、风景、动漫、食材等600+个常见物体，接口返回大类及细分类的名称结果</p>
              </div>
            </section>
          </Col>
          <Col span={8}>
            <section className="box1">
              <img src={img2} style={{ float: 'right' }} alt="" />
              <div>
                <h3>支持 API 调用</h3>
                <p>支持API在线调用，按次数收费。免费测试，随调随用</p>
              </div>
            </section>
          </Col>
          <Col span={8}>
            <div className="box1">
              <img src={img3} style={{ float: 'right' }} alt="" />
              <div>
                <h3>支持私有化部署</h3>
                <p>支持私有化定制服务，合作详询：021-50211738</p>
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ padding: 20, backgroundColor: '#fff', marginTop: 20, flex: 1 }}>
          <Tabs
            items={[
              {
                key: 'demo',
                label: '在线 demo',
                children: (
                  <Row style={{ marginTop: 20 }}>
                    <Col span={16}>
                      <Row>
                        <div style={{ height: 520, flex: 'auto' }}>
                          <img src="" alt="" />
                        </div>
                        <Carousel {...settings} style={{ width: 200 }}>
                          <div>
                            <img src="" alt="" style={{ height: 96, width: 174 }} />1
                          </div>
                          <div>
                            <img src="" alt="" style={{ height: 96, width: 174 }} />2
                          </div>
                          <div>
                            <img src="" alt="" style={{ height: 96, width: 174 }} />3
                          </div>
                          <div>
                            <img src="" alt="" style={{ height: 96, width: 174 }} />4
                          </div>
                          <div>
                            <img src="" alt="" style={{ height: 96, width: 174 }} />5
                          </div>
                        </Carousel>
                      </Row>
                      <Row>
                        <Space.Compact style={{ position: 'relative', flex: 'auto' }} size="large">
                          <Input style={{ paddingRight: 70 }} placeholder="请输入网络图片URL"></Input>
                          <div style={{ position: 'absolute', right: 0, zIndex: 111 }}>
                            <Divider type="vertical"></Divider>
                            <Button type="text">检测</Button>
                          </div>
                        </Space.Compact>
                        <Button type="primary" size="large" style={{ marginLeft: 20 }}>
                          本地上传
                        </Button>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Card
                        title={'检测结果'}
                        size="small"
                        style={{ borderRadius: 8 }}
                        headStyle={{ backgroundColor: '#EDF0F9' }}
                        bodyStyle={{ backgroundColor: '#F5F6FA' }}
                      >
                        <table className="table-bordered" style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td style={{ backgroundColor: '#EDF0F9', height: 50, paddingLeft: 10 }}>人</td>
                              <td style={{ backgroundColor: '#fff', paddingLeft: 10 }}>0.78</td>
                            </tr>
                            <tr>
                              <td style={{ backgroundColor: '#EDF0F9', height: 50, paddingLeft: 10 }}>人</td>
                              <td style={{ backgroundColor: '#fff', paddingLeft: 10 }}>0.78</td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                    </Col>
                  </Row>
                )
              },
              {
                key: 'api',
                label: 'API 调用',
                children: <div>11</div>
              }
            ]}
          ></Tabs>
        </div>
      </div>
    </div>
  );
};

export default Detection;
