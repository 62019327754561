import { Checkbox, Form, Modal, ModalProps, Row, Space, Switch, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { UserGroup } from 'types/userGroup';
import { AppName } from 'utils/constants';
import Section from './Section';
import { upperFirst } from 'lodash';

type IAppPermission = Record<keyof typeof AppName, React.ReactNode>;

const PermissionDialog: React.FC<
  ModalProps & {
    values?: UserGroup.AsObject['services'];
    onSubmit: (values: UserGroup.AsObject['services']) => void;
  }
> = observer(({ onOk, onSubmit, values, ...rest }) => {
  const [form] = Form.useForm();

  const la = Form.useWatch(['la', 'permissions'], form);
  const ti = Form.useWatch(['ti', 'permissions'], form);
  const mo = Form.useWatch(['mo', 'permissions'], form);
  const hubble = Form.useWatch(['hubble', 'permissions'], form);

  const arrayNum = (num: number) => Array.from(Array(num)).map((v, i) => i + 1);

  const setValue = (obj: { [k: string]: any }, path: string[]) => {
    Object.entries(obj).forEach(([k, v]) => {
      form.setFieldValue([...path, k], arrayNum(v ? 20 : 0));
    });
  };

  const setValue2 = (obj?: { [k: string]: any }) => {
    obj &&
      Object.entries(obj).forEach(([k, v]) => {
        obj[k] = Array.isArray(v) ? !!v.length : v;
      });
  };

  useEffect(() => {
    form.resetFields();
    if (values) {
      const { la, hubble, ti, mo } = values;

      setValue(la.permissions, ['la', 'permissions']);
      setValue(hubble.permissions, ['hubble', 'permissions']);
      setValue(ti.permissions, ['ti', 'permissions']);
      setValue(mo.permissions, ['mo', 'permissions']);

      form.setFieldValue(['la', 'state'], la.state);
      form.setFieldValue(['hubble', 'state'], hubble.state);
      form.setFieldValue(['ti', 'state'], ti.state);
      form.setFieldValue(['mo', 'state'], mo.state);
    }
  }, [values, form]);

  const AppPermission = {
    la: (
      <>
        {/* <Section
          title={
            <Checkbox
              checked={la?.['la:system:managing']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['la', 'permissions', 'la:system:managing'], arrayNum(2));
                } else {
                  form.setFieldValue(['la', 'permissions', 'la:system:managing'], arrayNum(0));
                }
              }}
            >
              系统管理
            </Checkbox>
          }
        >
          <Form.Item name={['la', 'permissions', 'la:system:managing']}>
            <Checkbox.Group disabled>
              <Checkbox value={1}>发包工作</Checkbox>
              <Checkbox value={2}>系统管理</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Section> */}
        <Section
          title={
            <Checkbox
              checked={la?.['la:work:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['la', 'permissions', 'la:work:using'], arrayNum(3));
                } else {
                  form.setFieldValue(['la', 'permissions', 'la:work:using'], arrayNum(0));
                }
              }}
            >
              我的工作
            </Checkbox>
          }
        >
          <Form.Item name={['la', 'permissions', 'la:work:using']}>
            <Checkbox.Group disabled>
              <Checkbox value={1}>每日工作</Checkbox>
              <Checkbox value={2}>分包标注</Checkbox>
              <Checkbox value={3}>账号信息</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Section>
        <Section
          title={
            <Checkbox
              checked={la?.['la:release:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['la', 'permissions', 'la:release:using'], arrayNum(3));
                } else {
                  form.setFieldValue(['la', 'permissions', 'la:release:using'], arrayNum(0));
                }
              }}
            >
              发包工作
            </Checkbox>
          }
        >
          <Form.Item name={['la', 'permissions', 'la:release:using']}>
            <Checkbox.Group disabled>
              <Checkbox value={1}>标注任务</Checkbox>
              <Checkbox value={2}>任务模版</Checkbox>
              <Checkbox value={3}>评估方案</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Section>
        <Section
          title={
            <Checkbox
              checked={la?.['la:system:managing']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['la', 'permissions', 'la:system:managing'], arrayNum(3));
                } else {
                  form.setFieldValue(['la', 'permissions', 'la:system:managing'], arrayNum(0));
                }
              }}
            >
              系统管理
            </Checkbox>
          }
        >
          <Form.Item name={['la', 'permissions', 'la:system:managing']}>
            <Checkbox.Group disabled>
              <Checkbox value={2}>工作绩效管理</Checkbox>
              <Checkbox value={3}>计价类型管理</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Section>
      </>
    ),
    ti: (
      <>
        <Section
          title={
            <Checkbox
              checked={ti?.['ti:jobs:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['ti', 'permissions', 'ti:jobs:using'], arrayNum(1));
                } else {
                  form.setFieldValue(['ti', 'permissions', 'ti:jobs:using'], arrayNum(0));
                }
              }}
            >
              使用任务
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['ti', 'permissions', 'ti:jobs:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>训练</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>
        <Section
          title={
            <Checkbox
              checked={ti?.['ti:jobs:managing']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['ti', 'permissions', 'ti:jobs:managing'], arrayNum(1));
                } else {
                  form.setFieldValue(['ti', 'permissions', 'ti:jobs:managing'], arrayNum(0));
                }
              }}
            >
              管理任务
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['ti', 'permissions', 'ti:jobs:managing']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>队列管理</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>
        <Section
          title={
            <Checkbox
              checked={ti?.['ti:storages:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['ti', 'permissions', 'ti:storages:using'], arrayNum(1));
                } else {
                  form.setFieldValue(['ti', 'permissions', 'ti:storages:using'], arrayNum(0));
                }
              }}
            >
              使用内部存储
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['ti', 'permissions', 'ti:storages:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>训练时使用内部存储</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>
        <Section
          title={
            <Checkbox
              checked={ti?.['ti:storages:managing']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['ti', 'permissions', 'ti:storages:managing'], arrayNum(1));
                } else {
                  form.setFieldValue(['ti', 'permissions', 'ti:storages:managing'], arrayNum(0));
                }
              }}
            >
              管理内部存储
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['ti', 'permissions', 'ti:storages:managing']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>内部存储</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>

        <Section
          title={
            <Checkbox
              checked={ti?.['ti:datasets:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['ti', 'permissions', 'ti:datasets:using'], arrayNum(1));
                } else {
                  form.setFieldValue(['ti', 'permissions', 'ti:datasets:using'], arrayNum(0));
                }
              }}
            >
              使用数据集
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['ti', 'permissions', 'ti:datasets:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>训练时使用数据集</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>

        <Section
          title={
            <Checkbox
              checked={ti?.['ti:datasets:managing']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['ti', 'permissions', 'ti:datasets:managing'], arrayNum(1));
                } else {
                  form.setFieldValue(['ti', 'permissions', 'ti:datasets:managing'], arrayNum(0));
                }
              }}
            >
              管理数据集
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['ti', 'permissions', 'ti:datasets:managing']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>管理数据集</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>

        <Section
          title={
            <Checkbox
              checked={ti?.['ti:system-resources:managing']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['ti', 'permissions', 'ti:system-resources:managing'], arrayNum(1));
                } else {
                  form.setFieldValue(['ti', 'permissions', 'ti:system-resources:managing'], arrayNum(0));
                }
              }}
            >
              系统资源
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['ti', 'permissions', 'ti:system-resources:managing']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>套餐管理</Checkbox>
                  <Checkbox value={2}>配额管理</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>
      </>
    ),
    mo: (
      <>
        <Section
          title={
            <Checkbox
              disabled
              checked={mo?.['mo:system:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['mo', 'permissions', 'mo:system:using'], arrayNum(6));
                } else {
                  form.setFieldValue(['mo', 'permissions', 'mo:system:using'], arrayNum(0));
                }
              }}
            >
              模型管理
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['mo', 'permissions', 'mo:system:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>PTH模型</Checkbox>
                  <Checkbox value={2}>TRON模型</Checkbox>
                  <Checkbox value={2}>模型量化任务</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>

        <Section
          title={
            <Checkbox
              disabled
              checked={mo?.['mo:system:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['mo', 'permissions', 'mo:system:using'], arrayNum(6));
                } else {
                  form.setFieldValue(['mo', 'permissions', 'mo:system:using'], arrayNum(0));
                }
              }}
            >
              模型转换
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['mo', 'permissions', 'mo:system:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>模型结构管理</Checkbox>
                  <Checkbox value={2}>模型转换模板</Checkbox>
                  <Checkbox value={2}>模型转换任务</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>

        <Section
          title={
            <Checkbox
              disabled
              checked={mo?.['mo:system:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['mo', 'permissions', 'mo:system:using'], arrayNum(6));
                } else {
                  form.setFieldValue(['mo', 'permissions', 'mo:system:using'], arrayNum(0));
                }
              }}
            >
              模型推理
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['mo', 'permissions', 'mo:system:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>数据集管理</Checkbox>
                  <Checkbox value={2}>模型推理测试</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>
      </>
    ),
    hubble: (
      <div>
        <Section
          title={
            <Checkbox
              disabled
              checked={hubble?.['hubble:system:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['hubble', 'permissions', 'hubble:system:using'], arrayNum(6));
                } else {
                  form.setFieldValue(['hubble', 'permissions', 'hubble:system:using'], arrayNum(0));
                }
              }}
            >
              数据检索
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['hubble', 'permissions', 'hubble:system:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>LabelX</Checkbox>
                  <Checkbox value={2}>训练集</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>

        <Section
          title={
            <Checkbox
              disabled
              checked={hubble?.['hubble:system:using']?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  form.setFieldValue(['hubble', 'permissions', 'hubble:system:using'], arrayNum(6));
                } else {
                  form.setFieldValue(['hubble', 'permissions', 'hubble:system:using'], arrayNum(0));
                }
              }}
            >
              数据管理
            </Checkbox>
          }
        >
          <Row>
            <Space>
              <Form.Item name={['hubble', 'permissions', 'hubble:system:using']}>
                <Checkbox.Group disabled>
                  <Checkbox value={1}>数据工作台</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Space>
          </Row>
        </Section>
      </div>
    )
  };

  const items =
    values &&
    Object.keys(values)
      .filter((v) => v !== 'idp')
      .map((app) => {
        return {
          label: (
            <Row justify={'space-between'} align={'middle'} style={{ width: 200 }}>
              <span style={{ fontWeight: 'bold' }}>{upperFirst(app.replace('hubble', 'h'))}</span>
              <Form.Item name={[app, 'state']} style={{ marginBottom: 0 }} valuePropName="checked">
                <Switch></Switch>
              </Form.Item>
            </Row>
          ),
          key: app,
          children: AppPermission[app as keyof typeof AppPermission]
        };
      });

  return (
    <Modal
      width="60vw"
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      maskClosable={false}
      onOk={async () => {
        const values = form.getFieldsValue();

        const { la, hubble, ti, mo } = values;

        setValue2(la?.permissions);
        setValue2(hubble?.permissions);
        setValue2(ti?.permissions);
        setValue2(mo?.permissions);

        onSubmit(values);
      }}
      {...rest}
    >
      <Form form={form}>
        <Tabs tabPosition="left" items={items}></Tabs>
      </Form>
    </Modal>
  );
});

export default PermissionDialog;
