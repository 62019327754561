import React, { PropsWithChildren } from 'react';

import './Section.less';

const Section: React.FC<
  PropsWithChildren<{
    title: React.ReactNode;
    backgroundColor?: string | null;
    style?: React.CSSProperties;
    extra?: React.ReactNode;
  }>
> = ({ title, backgroundColor, children, style, extra }) => {
  const bgColor = backgroundColor === null ? undefined : backgroundColor || '#f4f7ff';

  return (
    <section className="section" style={style}>
      <header className="header">
        <h3 className="title">{title}</h3>
        <div>{extra}</div>
      </header>
      <div className="content" style={{ backgroundColor: bgColor, padding: bgColor ? 20 : 0 }}>
        {children}
      </div>
    </section>
  );
};

export default Section;
