import { Button, Row, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Conversation, Message, Model } from 'types/chat';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

import './Chat.less';
import { last, throttle } from 'lodash';
import { withAbort } from 'utils/index';
import { EventSourceParserStream } from 'eventsource-parser/dist/stream';
import { observer } from 'mobx-react';
import clearIcon from 'src/assets/service/clear.png';

type IChatProps = {
  conversions: Conversation[];
  onClearConversion?: () => any;
  apiHost: string;
  style?: React.CSSProperties;
};

const Chat: React.FC<IChatProps> = ({ conversions, style, apiHost }) => {
  const conversion = conversions[0];

  const [question, setQuestion] = useState('');
  const [answering, setAnswering] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [models, setModels] = useState<Model[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>();

  const chatBody = useRef<HTMLDivElement>(null);

  const abortControlRef = useRef<AbortController>();

  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    chatBody.current?.scrollTo({
      top: chatBody.current.scrollHeight,
      behavior
    });
  };

  const throttledScrollToBottom = throttle(scrollToBottom, 250);

  const onSend = async (question: string) => {
    try {
      const abortControl = await withAbort(async ({ signal }) => {
        const response = await fetch(`${apiHost}/v1/chat/completions`, {
          signal,
          method: 'POST',
          headers: {
            Authorization: 'Bearer  ',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            stream: true,
            model: selectedModel,
            messages: [
              ...conversion.messages,
              {
                role: 'user',
                content: question
              }
            ]
          })
        });

        conversion.messages.push({ content: question, role: 'user' });

        setQuestion('');

        if (response.ok) {
          setAnswering(true);

          conversion.messages.push({
            content: '',
            role: 'assistant'
          });

          const answerMessage = last(conversion.messages)!;

          const eventStream = response
            .body!.pipeThrough(new TextDecoderStream())
            .pipeThrough(new EventSourceParserStream());

          const res = new Response(eventStream);

          const reader = res.body!.getReader();

          while (true) {
            const { value, done } = await reader.read();

            if (done) {
              break;
            }

            try {
              const data = (value as any).data;
              const json = JSON.parse(data);
              if (json.choices[0].finish_reason != null) {
                return;
              }
              const text = json.choices[0].delta.content;
              if (text) {
                answerMessage.content += text;
              }
            } catch (e) {
              console.log(e);
            }

            autoScroll && throttledScrollToBottom();
          }
        } else {
          message.error(response.statusText);
        }
      });

      abortControlRef.current = abortControl;
    } catch (err) {
      conversion.messages.push({ content: question, role: 'user' });
      autoScroll && throttledScrollToBottom();
    }

    setAnswering(false);
  };

  const onScroll = () => {
    if (chatBody.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBody.current;
      const bottomTolerance = 0;

      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        console.log(false);
        setAutoScroll(false);
      } else {
        setAutoScroll(true);
        console.log(true);
      }
    }
  };

  const getModels = () => {
    return fetch(`${apiHost}/v1/models`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        return data.data;
      });
  };

  useEffect(() => {
    getModels().then((models) => {
      setModels(models);
      setSelectedModel(models[0].id);
    });
  }, []);

  return (
    <div className="chat" style={style}>
      <div ref={chatBody} onScroll={onScroll} className="chat-body" style={{ flex: 'auto' }}>
        {conversion.messages.map((message, idx) => {
          return <ChatMessage key={idx} message={message}></ChatMessage>;
        })}
      </div>
      <Row>
        <ChatInput
          value={question}
          onSend={onSend}
          onChange={(text) => {
            setQuestion(text);
          }}
          disabled={answering || !question}
          style={{ flex: 'auto', marginRight: 20 }}
          model={selectedModel!}
          models={models}
          onModelChange={(v) => setSelectedModel(v)}
        ></ChatInput>
        <Button
          onClick={() => {
            conversion.messages = [];
          }}
          icon={<img src={clearIcon}></img>}
          size="large"
        ></Button>
        {/* <Button
          type="text"
          onClick={() => {
            abortControlRef.current?.abort();
          }}
        >
          Stop
        </Button> */}
      </Row>
    </div>
  );
};

export default observer(Chat);
