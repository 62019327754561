import { SwapOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, PageHeader, Row, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Api from 'api/Api';
import ApplyServiceDialog from 'components/ApplyServiceDialog';
import { H, La, Mo, Ti } from 'components/Apps';
import Section from 'components/Section';
import AtomTable from 'components/Table';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { userPortalStore } from 'src/stores/userPortal';
import { Member } from 'types/member';
import { Service } from 'types/service';
import { format } from 'utils/date';
import { login, logout } from 'utils/index';

const AppMaps = {
  Hubble: <H />,
  Mo: <Mo />,
  Ti: <Ti />,
  La: <La />
};

const Org = observer(() => {
  const [open, setOpen] = useState(false);

  const columns: ColumnProps<any>[] = [
    {
      title: '应用名称',
      dataIndex: 'name',
      render(value, record, index) {
        return AppMaps[value as keyof typeof AppMaps];
      }
    },
    {
      title: '授权用户数量',
      render(value, record, index) {
        return 111;
      }
    },
    {
      title: '资源',
      render(value, record, index) {
        return <Tag>25T/100T</Tag>;
      }
    }
  ];

  useEffect(() => {
    userPortalStore.fetchOrganizations();
    userPortalStore.fetchUserGroups();
  }, []);

  const ownGroups = userPortalStore.groups;

  return (
    <PageHeader style={{ backgroundColor: '#fff', minHeight: '100%' }} className="atom-shadow">
      <Section
        title={<span>账号信息</span>}
        extra={
          <Dropdown
            overlay={
              <Menu
                className="atom-menu"
                onClick={async (info) => {
                  await logout();
                  login(info.key);
                }}
              >
                {userPortalStore.orgs
                  .filter((org) => org.id !== userPortalStore.user.tenant_id)
                  .map((org) => {
                    return (
                      <Menu.Item key={org.name}>
                        <div>{org.name}</div>
                      </Menu.Item>
                    );
                  })}
              </Menu>
            }
          >
            <Button type="primary" className="atom-btn" icon={<SwapOutlined />}>
              {userPortalStore.user.tenant_name}
            </Button>
          </Dropdown>
        }
      >
        <Row style={{ marginBottom: 20 }}>
          <Col style={{ width: 120 }}>用户类型:</Col>
          <Col>{userPortalStore.isManager ? '单位管理员' : '成员用户'}</Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col style={{ width: 120 }}>用户组:</Col>
          <Col>
            {ownGroups?.map((v, idx) => {
              return (
                <Tag key={idx} style={{ backgroundColor: '#fff', border: 0 }}>
                  {v.name}
                </Tag>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 120 }}>可访问应用:</Col>
          <Col>
            {userPortalStore.applications?.map((app) => {
              return (
                <Tag key={app.name} style={{ padding: '8px 12px', border: 0, backgroundColor: '#fff' }}>
                  {AppMaps[app.name as keyof typeof AppMaps]}
                </Tag>
              );
            })}
          </Col>
        </Row>
      </Section>
      <Section title={'单位信息'}>
        <Row>
          <Row style={{ width: '50%', marginBottom: 20 }}>
            <Col style={{ width: 120 }}>单位管理员:</Col>
            <Col>
              {userPortalStore.org.managers?.map((v, idx) => {
                return <span key={idx}>{v} </span>;
              })}
            </Col>
          </Row>
          <Row style={{ width: '50%', marginBottom: 20 }}>
            <Col style={{ width: 120 }}>成员数量:</Col>
            <Col>{userPortalStore.org.members?.filter((v) => v.state === Member.State.Active)?.length}人</Col>
          </Row>
          <Row style={{ width: '50%' }}>
            <Col style={{ width: 120 }}>单位创建时间:</Col>
            <Col>{format(userPortalStore.org?.created_at)}</Col>
          </Row>
        </Row>
      </Section>
      <Section
        style={{ display: 'none' }}
        backgroundColor={null}
        title={
          <Row align={'middle'} justify={'space-between'}>
            <span>应用信息</span>
            {/* {userPortalStore.isManager && (
              <Button style={{ color: '#1890ff', borderColor: '#1890ff' }} onClick={() => setOpen(true)}>
                申请开通新应用
              </Button>
            )} */}
          </Row>
        }
      >
        <AtomTable
          columns={columns}
          dataSource={userPortalStore.applications}
          pagination={false}
          header={false}
        ></AtomTable>
      </Section>

      <ApplyServiceDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={async (fields) => {
          await Api.service.createOrganizationService(userPortalStore.org.name, {
            name: fields.name,
            state: Service.State.Applying
          });
          setOpen(false);
        }}
      ></ApplyServiceDialog>
    </PageHeader>
  );
});

export default Org;
