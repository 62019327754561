import React from 'react'
import { useEffect } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { PageHeader, Card, Button, Table, Popconfirm } from 'antd'
import { useStore } from 'utils/stateStore'
import { ColumnsType } from 'antd/lib/table'
import Api from 'api/Api'
import { Service } from 'types/service'

class Status {
  services: Service.ServiceRequest[] = []
  page = 1
  page_size = 10
  total: number = undefined
  curService: Service.AsObject = undefined
}

export const OrgServiceApply = () => {
  const { status } = useStore(new Status())

  const fetchService = async () => {
    const res = await Api.service.listServiceRequests({ state: [Service.State.Applying] })
    status.services = _.sortBy(res, (i) => -moment(i.created_at).valueOf())
  }

  useEffect(() => {
    fetchService()
  }, [])

  const columns: ColumnsType<Service.ServiceRequest> = [
    {
      title: '单位名称',
      width: 200,
      ellipsis: true,
      dataIndex: 'organization'
    },
    {
      title: '申请账号',
      align: 'center',
      dataIndex: 'creator'
    },
    {
      title: '申请应用',
      align: 'center',
      dataIndex: 'application'
    },
    {
      title: '申请日期',
      align: 'center',
      dataIndex: 'created_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      width: 240,
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Popconfirm
              title="确认要批准这个单位应用申请么？"
              onConfirm={async () => {
                await Api.service.updateOrganizationService(record.organization, record.application, {
                  state: Service.State.Active
                })
                await fetchService()
              }}
            >
              <Button type="link" size="small">
                批准
              </Button>
            </Popconfirm>
            <Popconfirm
              title="确认要拒绝这个单位应用申请么？"
              onConfirm={async () => {
                await Api.service.updateOrganizationService(record.organization, record.application, {
                  state: Service.State.Rejected
                })
                await fetchService()
              }}
            >
              <Button type="link" size="small">
                拒绝
              </Button>
            </Popconfirm>
          </div>
        )
      }
    }
  ]
  return (
    <PageHeader
      title="单位应用审批"
      style={{
        background: '#FFF'
      }}
    >
      <Card>
        <Table<Service.ServiceRequest>
          rowKey="id"
          columns={columns}
          dataSource={status.services}
          bordered={true}
          pagination={{
            total: status.total,
            pageSize: status.page_size,
            showSizeChanger: false,
            onChange: async (page: number, page_size: number) => {
              status.page = page
              status.page_size = page_size
              await fetchService()
            }
          }}
        />
      </Card>
    </PageHeader>
  )
}
