import { Modal, ModalProps, Transfer } from 'antd';
import type { TransferDirection } from 'antd/es/transfer';
import React, { useEffect, useState } from 'react';

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}

const MemberDialog: React.FC<
  ModalProps & {
    dataSource: any[];
    titles: React.ReactNode[];
    onSubmit: (values: any[]) => void;
  }
> = ({ dataSource, titles, onSubmit, ...modalProps }) => {
  const [targetKeys, setTargetKeys] = useState<string[]>([]);

  useEffect(() => {
    if (dataSource) {
      setTargetKeys(dataSource.filter((v) => v.chosen).map((v) => v.key));
    }
  }, [dataSource]);

  const filterOption = (inputValue: string, option: RecordType) => option.title.indexOf(inputValue) > -1;

  const handleChange = (newTargetKeys: string[]) => {
    setTargetKeys(newTargetKeys);
  };

  const handleSearch = (dir: TransferDirection, value: string) => {
    console.log('search:', dir, value);
  };

  return (
    <Modal
      onOk={() => {
        onSubmit(targetKeys);
      }}
      {...modalProps}
    >
      <Transfer
        dataSource={dataSource}
        showSearch
        filterOption={filterOption}
        targetKeys={targetKeys}
        onChange={handleChange}
        onSearch={handleSearch}
        render={(item) => item.title}
        titles={titles}
        listStyle={{ height: '60vh', width: 220 }}
      />
    </Modal>
  );
};

export default MemberDialog;
