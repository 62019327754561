import { User } from 'src/types/user';
import axios from '../utils/axios';

export interface ISignInResp {
  code: number
  record?: User.AsObject
  err_msg?: string
}

export interface IGetUserIdResp {
  code: number
  record?: number
  err_msg?: string
}

export interface IGetUsersReq {
  name: string
  page: number
  page_size: number
}

export interface IGetUsersResp {
  code: number
  record?: {
    total: number
    records: User.AsObject[]
  }
  err_msg?: string
}

export interface IGetUserResp {
  email: string
  member_id: string
  sub: string
  tenant_id: string
  tenant_name: string
  username: string
}

export interface ICreateUserReq {
  name: string
  nick_name: string
  password: string
  email: string
  role_id: number
}

export interface ICreateUserResp {
  code: number
  err_msg?: string
}

export interface IUpdateUserReq {
  system_roles: string[]
}

export interface IUpdateUserResp {
  code: number
  data: User.AsObject
  err_msg?: string
}

export interface IEditUserReq {
  name: string
  nick_name: string
  email: string
  role_ids: number[]
  state: User.UserState
}

export interface IEditUserResp {
  code: number
  err_msg?: string
}

export interface ISimulateLoginResp {
  code: number
  record?: string
  err_msg?: string
}

export interface IListUsersReq {
  name_pattern?: string
  page_number?: number
  page_size?: number
}

export interface IListUsersResp {
  page: { number: number; size: number; total: number }
  users: User.AsObject[]
}


// admin-portal

export const getUserInfo = (): Promise<IGetUserResp> => {
  return axios.get('/v2/login/identity')
}

export const logout = (): Promise<void> => {
  return axios.get('/v2/login/logout')
}

export const disableUser = (user_name: string): Promise<{ code: number; msg: string }> => {
  return axios.put(`/api/v1/users/${user_name}`, { state: User.UserState.Freezed })
}

export const enableUser = (user_name: string): Promise<{ code: number; msg: string }> => {
  return axios.put(`/api/v1/users/${user_name}`, { state: User.UserState.Active })
}

export const changePassword = (params: { id: number; old_passwd: string; new_passwd: string }): Promise<any> => {
  return axios.post(`/v1/users/update_passwd`, params)
}

export const simulateLogin = (orgId: number): Promise<ISimulateLoginResp> => {
  return axios.get(`/auth/simulate_login/${orgId}`)
}

// v2
export const getUser = (user_name: string): Promise<User.AsObject> => {
  return axios.get(`/api/v1/users/${user_name}`)
}

export const listUsers = (params: IListUsersReq): Promise<IListUsersResp> => {
  return axios.get('/api/v1/users', { params })
}

export const updateUser = (user_name: string, params: IUpdateUserReq): Promise<IUpdateUserResp> => {
  return axios.put(`/api/v1/users/${user_name}`, params)
}

// 常用模块
export const listRecentlyLoginJournals = (): Promise<any> => {
  return axios.get('/api/v1/journals/login/recent')
}
