import { observer } from 'mobx-react';
import React from 'react';
import { Navigate } from 'react-router';
import { userPortalStore } from 'src/stores/userPortal';

const Index = observer(() => {
  const hasService =
    Reflect.has(userPortalStore.org, 'name') &&
    Reflect.has(userPortalStore.org, 'services') &&
    userPortalStore.org.services?.length;

  return !hasService ? <Navigate replace to="welcome" /> : <Navigate replace to="org" />;
});

export default Index;
