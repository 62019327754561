import { Form, Modal, ModalProps, Radio, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Api from 'api/Api';
import React, { useEffect, useState } from 'react';
import { userPortalStore } from 'src/stores/userPortal';
import { UserGroup } from 'types/userGroup';

const MemberInfoDialog: React.FC<
  ModalProps & {
    onSubmit(values: { isManager: boolean; groups: string[] }): void;
    values?: { isManager?: boolean; groups?: string[] };
  }
> = ({ onOk, onSubmit, values, ...modalProps }) => {
  const [groups, setGroups] = useState<UserGroup.AsObject[]>([]);
  const [form] = useForm();

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    }
  }, [values]);

  useEffect(() => {
    Api.organization.listUserGroups(userPortalStore.user.tenant_id).then((data) => {
      setGroups(data.user_groups || []);
    });
  }, []);

  return (
    <Modal
      width="40vw"
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      title="成员信息编辑"
      maskClosable={false}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          onSubmit(values);
        } catch (err) {}
      }}
      {...modalProps}
    >
      <Form form={form}>
        <Form.Item label={'单位管理员'} name={'isManager'} required>
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={'用户组'} name={'groups'}>
          <Select mode="multiple">
            {groups.map((v) => {
              return <Select.Option key={v.id}>{v.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MemberInfoDialog;
