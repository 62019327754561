import { useState } from 'react'

const MaxDepth = 1

const deepProxy = (obj, setFunc) => {
  let i = 0

  const addProxy = (innerObj) => {
    i++

    if (i <= MaxDepth) {
      Object.keys(innerObj).forEach((innerKey) => {
        if (typeof innerObj[innerKey] === 'object' && innerObj[innerKey] !== null) {
          innerObj[innerKey] = addProxy(innerObj[innerKey])
        }
      })
    }

    innerObj = new Proxy(innerObj, {
      set(target: any, p: PropertyKey, value: any): boolean {
        target[p] = value
        setFunc(Object.assign({}, obj))
        return true
      }
    })
    return innerObj
  }

  return addProxy(obj)
}

export const useStore = <T>(
  initStatus: T
): {
  status: T
  setStatus: (object: Partial<T>) => any
} => {
  const [status, setStatus] = useState(initStatus)

  const wrapperSetStatus = (partialObj) => {
    setStatus(Object.assign({}, status, partialObj))
  }

  return {
    status: deepProxy(status, setStatus),
    setStatus: (partialObj) => wrapperSetStatus(partialObj)
  }
}
