import Api from 'api/Api';

export const login = (org?: string) => {
  const params = new URLSearchParams();

  if (org) {
    params.append('org', org);
  }

  params.append('return_to', location.href);

  window.location.href = `/v2/login/browser?${params.toString()}`;
};

export const logout = async () => {
  try {
    await Api.user.logout();
    return true;
  } catch (err) {
    return false;
  }
};

export const withAbort = async (cb: (arg: { signal: AbortSignal }) => Promise<any> | any) => {
  const abortControl = new AbortController();

  await cb({ signal: abortControl.signal });

  return abortControl;
};
