import React from 'react'
import { useEffect } from 'react'
import { PageHeader, Card, Form, Input, Button, Select } from 'antd'
import {  useNavigate, useParams } from 'react-router'
import Api from 'api/Api'
import { useStore } from 'utils/stateStore'
import { Role, TranRole } from 'utils/constants'

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 18
  }
}

class Status {
  username: string = undefined
  fullname: string = undefined
  email: string = undefined
  isLoading = false
  systemRoles: string[] = []
}

export const UpdateUser: React.FC = (props) => {
  const { status } = useStore(new Status())
  const params = useParams()
  const navigate = useNavigate()

  const fetchUser = async () => {
    const userName = params.user_name
    const getUser = await Api.user.getUser(userName)
    status.username = getUser.name
    status.fullname = getUser.fullname
    status.systemRoles = getUser.system_roles
    status.email = getUser.email
  }

  const updateUser = async () => {
    status.isLoading = true
    await Api.user.updateUser(params.user_name, {
      system_roles: status.systemRoles
    })
    status.isLoading = false
    navigate(-1)
  }

  const onChange = (value) => {
    status.systemRoles = value
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <PageHeader
      title="编辑用户"
      style={{
        background: '#fff'
      }}
      onBack={() => {
        navigate(-1)
      }}
    >
      <Card>
        <Form {...layout}>
          <Form.Item label={<span className="label-required">用户账号</span>}>
            <Input disabled placeholder="请输入用户账号" value={status.username} />
          </Form.Item>
          <Form.Item label={<span className="label-required">用户名称</span>}>
            <Input disabled value={status.fullname} />
          </Form.Item>
          <Form.Item label={<span className="label-required">邮箱</span>}>
            <Input disabled value={status.email} />
          </Form.Item>
          <Form.Item label="系统角色">
            <Select value={status.systemRoles} mode="multiple" onChange={onChange}>
              <Select.Option value={Role.UserAdmin}>{TranRole[Role.UserAdmin]}</Select.Option>
              <Select.Option value={Role.OrganizationAdmin}>{TranRole[Role.OrganizationAdmin]}</Select.Option>
              <Select.Option value={Role.ImpersonationAdmin}>{TranRole[Role.ImpersonationAdmin]}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <Button
                onClick={() => {
                  navigate(-1)
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: 20
                }}
                loading={status.isLoading}
                disabled={!status.username}
                onClick={updateUser}
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </PageHeader>
  )
}
