export namespace Invitation {
  export enum State {
    Pending = 'pending',
    Accepted = 'accepted',
    Rejected = 'rejected'
  }

  export interface AsObject {
    id: string
    invitee: string
    organization: string
    inviter: string
    state: State
    created_at: Date
    updated_at: Date
  }
}
