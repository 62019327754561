import { observer } from 'mobx-react';
import { BrowserRouter, Navigate, Route, Routes, useRoutes } from 'react-router-dom';
import { userPortalStore } from 'src/stores/userPortal';
import { NotFound } from '../pages/land/NotFound/NotFound';
import RequireLogin from './RequireLogin';

// Admin Portal
import { AdminPortal } from '../layouts/AdminPortal/AdminPortal';
import { AccountInfo } from '../pages/admin-portal/account-info/AccountInfo';
import { DetailOrg } from '../pages/admin-portal/org-apply/DetailOrg/DetailOrg';
import { OrgApply } from '../pages/admin-portal/org-apply/OrgApply';
import { CreateOrg } from '../pages/admin-portal/org-list/CreateOrg/CreateOrg';
import { OrgList } from '../pages/admin-portal/org-list/OrgList';
import { UpdateOrg } from '../pages/admin-portal/org-list/UpdateOrg/UpdateOrg';
import { OrgServiceApply } from '../pages/admin-portal/org-service-apply/OrgServiceApply';
import { CreateRole } from '../pages/admin-portal/role-manage/CreateRole/CreateRole';
import { RoleManage } from '../pages/admin-portal/role-manage/RoleManage';
import { UpdateRole } from '../pages/admin-portal/role-manage/UpdateRole/UpdateRole';
import { CreateUser } from '../pages/admin-portal/user-manage/CreateUser/CreateUser';
import { UpdateUser } from '../pages/admin-portal/user-manage/UpdateUser/UpdateUser';
import { UserManage } from '../pages/admin-portal/user-manage/UserManage';
import Login from './Login';

const UserRouter = observer(() => {
  return useRoutes(userPortalStore.routes);
});

const App = observer(() => {
  return (
    <BrowserRouter>
      <UserRouter></UserRouter>
      {/* admin routes */}
      <Routes>
        <Route path="/admin_portal" element={<Navigate replace to="/admin_portal/account_info" />} />
        <Route
          path="/admin_portal"
          element={
            <RequireLogin fallback={<Login></Login>}>
              <AdminPortal></AdminPortal>
            </RequireLogin>
          }
        >
          <Route path="/admin_portal/account_info" element={<AccountInfo></AccountInfo>} />
          <Route path="/admin_portal/user_manage" element={<UserManage></UserManage>} />
          <Route path="/admin_portal/user_manage/create" element={<CreateUser></CreateUser>} />
          <Route path="/admin_portal/user_manage/:user_name/update" element={<UpdateUser></UpdateUser>} />
          <Route path="/admin_portal/role_manage" element={<RoleManage></RoleManage>} />
          <Route path="/admin_portal/role_manage/create" element={<CreateRole></CreateRole>} />
          <Route path="/admin_portal/role_manage/:id/update" element={<UpdateRole></UpdateRole>} />
          <Route path="/admin_portal/org_list" element={<OrgList></OrgList>} />
          <Route path="/admin_portal/org_list/create" element={<CreateOrg></CreateOrg>} />
          <Route path="/admin_portal/org_list/:org_name/update" element={<UpdateOrg></UpdateOrg>} />
          <Route path="/admin_portal/org_apply" element={<OrgApply></OrgApply>} />
          <Route path="/admin_portal/org_service_apply" element={<OrgServiceApply></OrgServiceApply>} />
          <Route path="/admin_portal/org_apply/:org_name/detail" element={<DetailOrg></DetailOrg>} />
          <Route path="/admin_portal/*" element={<NotFound></NotFound>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
});

export default App;
