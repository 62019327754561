import * as User from './user.api';
import * as Application from './application.api';
import * as Invitation from './invitation.api';
import * as Member from './member.api';
import * as Organization from './organization.api';
import * as Service from './service.api';

const Api = {
  user: User,
  application: Application,
  invitation: Invitation,
  member: Member,
  organization: Organization,
  service: Service
};

export default Api;
