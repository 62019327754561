import { Button, Card, Col, Form, Input, Modal, ModalProps, Radio, Row, Select, message } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Api from 'api/Api';
import React, { useEffect, useState } from 'react';
import { Organization } from 'types/organization';
import { User } from 'types/user';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};

type IFormFields = {
  name: string;
  type: Organization.Type;
  managers: string[];
  contact: string;
  telephone: string;
  address: string;
  uscid: string;
};

type IEditOrgDialogProps = {
  fields?: Partial<IFormFields>;
  loading?: boolean;
  onFinish: (values: any, from: FormInstance<any>) => void;
} & ModalProps;

const EditOrgDialog: React.FC<IEditOrgDialogProps> = ({ fields, loading, onCancel, onFinish, ...modal }) => {
  const [users, setUsers] = useState<User.AsObject[]>([]);
  const [form] = Form.useForm();

  const fetchUsers = async () => {
    const listUsersRes = await Api.user.listUsers({
      page_size: 10000
    });

    setUsers(listUsersRes.users);
  };

  useEffect(() => {
    fetchUsers();
    form.setFieldsValue(fields);
  }, [fields]);

  return (
    <Modal
      {...modal}
      onCancel={onCancel}
      onOk={async () => {
        try {
          const values = await form.validateFields();

          onFinish(values, form);
        } catch (err) {
          console.log(err);
        }
      }}
    >
      <Form {...layout} form={form} onFinish={(values) => onFinish(values, form)}>
        <Form.Item label={'单位名称'} name="name" rules={[{ required: true }]}>
          <Input placeholder="请输入单位名称" />
        </Form.Item>
        <Form.Item label={'客户类型'} name="type" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={Organization.Type.Government}>政府用户</Radio>
            <Radio value={Organization.Type.Institution}>企业用户</Radio>
            <Radio value={Organization.Type.Personal}>个人用户</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={'单位管理员'} name="managers" rules={[{ required: true }]}>
          {/* <Input placeholder="请输入单位管理员" /> */}
          <Select mode="multiple" allowClear disabled>
            {users.map((i) => (
              <Select.Option value={i.name} key={i.name}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="联系人" name="contact">
          <Input placeholder="请输入联系人" />
        </Form.Item>
        <Form.Item
          label="联系电话"
          name="telephone"
          rules={[
            {
              pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
              message: '电话号码不符合规范'
            }
          ]}
        >
          <Input placeholder="请输入联系电话" />
        </Form.Item>
        <Form.Item label="通讯地址" name="address">
          <Input placeholder="请输入通讯地址" />
        </Form.Item>
        <Form.Item
          label="统一社会信用代码"
          name="uscid"
          rules={[
            {
              pattern: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/,
              message: '统一社会信用代码不符合规范'
            }
          ]}
        >
          <Input placeholder="请输入统一社会信用代码" />
        </Form.Item>
        {/* <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              onClick={onCancel}
            >
              取消
            </Button>
            <Button
              type="primary"
              loading={loading}
              style={{ marginLeft: 10 }}
              htmlType="submit"
            >
              确定
            </Button>
          </div>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default EditOrgDialog;
