import { Space } from "antd";
import React from "react";
import { HIcon, IIcon, LaIcon, MoIcon, TiIcon } from "./icons";

export const La = () => {
  return (
    <Space><LaIcon></LaIcon> LabelX 标注营运系统</Space>
  )
}

export const Ti = () => {
  return (
    <Space><TiIcon></TiIcon> 深度学习系统</Space>
  )
}

export const Mo = () => {
  return (
    <Space><MoIcon></MoIcon> 模型管理系统</Space>
  )
}

export const I = () => {
  return (
    <Space><IIcon></IIcon> 集成开发测试环境</Space>
  )
}

export const H = () => {
  return (
    <span><HIcon></HIcon> Hubble 检索引擎</span>
  )
}

export const Gis = () => {
  return (
    <span>GIS 开发系统</span>
  )
}
