import { Member } from './member';
import { Service } from './service';

export namespace Organization {
  export enum State {
    Active = 'active',
    Freezed = 'freezed',
    Applying = 'applying',
    Rejected = 'rejected',
    Revoked = 'revoked',
    Locked = 'locked'
  }

  export enum Type {
    Government = 'government',
    Institution = 'institution',
    Personal = 'personal'
  }

  export interface AsObject {
    id: string;
    name: string;
    creator: string;
    type: string;
    telephone: string;
    address: string;
    member_count: number;
    contact: string;
    uscid: string;
    managers?: string[];
    state: State;
    services?: Service.AsObject[];
    members: Member.AsObject[];
    created_at: Date;
    updated_at: Date;
    last_login_at: Date;
  }
}
