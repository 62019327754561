import React from 'react';
import moment from 'moment';
import Api from 'api/Api';
import { useEffect } from 'react';
import { PageHeader, Card, Form, Modal, Input, message, Button } from 'antd';
import { useStore } from '../../../utils/stateStore';
import axios from 'axios';
import { useNavigate } from 'react-router';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 }
};
class Status {
  userInfo: any = undefined;
  password: string = undefined;
  oldPassword: string = undefined;
  passwordConfirm: string = undefined;
  isChangePassword = false;
}

export const AccountInfo: React.FC<any> = () => {
  const { status } = useStore(new Status());
  const navigate = useNavigate();

  const loadData = async () => {
    const userInfoRes = await Api.user.getUserInfo();
    const getUser = await Api.user.getUser(userInfoRes.username);
    status.userInfo = { ...userInfoRes, ...getUser };
  };
  useEffect(() => {
    loadData();
  }, []);

  const changePassword = async () => {
    const { password, oldPassword } = status;
    const searchParams = new URLSearchParams();
    searchParams.append('password', password);
    searchParams.append('old_password', oldPassword);
    searchParams.append('method', 'password');
    try {
      await axios.post(`${location.origin}/sso/api/setting`, searchParams, {});
      message.success('密码修改成功');
      status.isChangePassword = false;
      await Api.user.logout();
      localStorage.clear();
      navigate('/');
    } catch (error) {
      console.log(error.response.data);
      return message.error(error.response.data);
    }
  };

  return (
    <PageHeader title="账号信息" style={{ background: '#FFF' }}>
      <Card>
        <Form {...layout}>
          {/* <Form.Item label="账号 ID">{status.userInfo?.tenant_id}</Form.Item> */}
          <Form.Item label="用户账号">{status.userInfo?.username}</Form.Item>
          <Form.Item label="用户名称">{status.userInfo?.fullname}</Form.Item>
          <Form.Item label="密码">
            <Button
              type="link"
              onClick={() => {
                status.isChangePassword = true;
              }}
            >
              修改密码
            </Button>
          </Form.Item>
          <Form.Item label="邮箱">{status.userInfo?.email}</Form.Item>
          <Form.Item label="账号创建日期">
            {moment(status.userInfo?.created_at).format('YYYY-MM-DD HH:mm:ss')}
          </Form.Item>
        </Form>
      </Card>

      {status.isChangePassword && (
        <Modal
          width="40vw"
          bodyStyle={{
            maxHeight: '70vh',
            overflowY: 'auto'
          }}
          title="修改密码"
          maskClosable={false}
          visible={true}
          onCancel={() => {
            status.isChangePassword = false;
          }}
          onOk={async () => {
            const { password, passwordConfirm } = status;
            if (password !== passwordConfirm) {
              message.warning('两次新密码输入不一致');
              return;
            }
            await changePassword();
          }}
        >
          <Form>
            <Form.Item label="旧密码">
              <Input
                placeholder="请输入旧密码"
                type="password"
                value={status.oldPassword}
                onChange={(event) => {
                  status.oldPassword = event.target.value;
                }}
              />
            </Form.Item>
            <Form.Item label="新密码">
              <Input
                placeholder="请输入新密码"
                type="password"
                value={status.password}
                onChange={(event) => {
                  status.password = event.target.value;
                }}
              />
            </Form.Item>
            <Form.Item label="确认新密码">
              <Input
                placeholder="请输入新密码"
                type="password"
                value={status.passwordConfirm}
                onChange={(event) => {
                  status.passwordConfirm = event.target.value;
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </PageHeader>
  );
};
