import React from 'react'
import { useEffect } from 'react'
import { Card, PageHeader, Form, Input, Button, Tag, Radio } from 'antd'
import Api from 'api/Api'
import { useStore } from 'utils/stateStore'
import { Role } from 'types/role'
import { useNavigate } from 'react-router'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 }
}

class Status {
  username: string = undefined
  nickname: string = undefined
  password: string = undefined
  email: string = undefined
  roleId: number = undefined
  isLoading = false
  roles: Role.AsObject[] = []
  perms: { id: number; name: string; permissions: any }[] = []
}

export const CreateUser: React.FC<any> = () => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()

  const getRoles = async () => {
    const getRolesRes = await Api.role.getRoles()
    status.roles = getRolesRes.record.records
  }

  const getPermissions = async () => {
    const getPermissionsRes = await Api.role.getPermissions()
    status.perms = getPermissionsRes.record
  }

  const createUser = async () => {
    status.isLoading = true
    const createRes = await Api.user.createUser({
      name: status.username,
      nick_name: status.nickname,
      password: status.password,
      role_id: status.roleId,
      email: status.email
    })
    status.isLoading = false
    if (createRes.code === 200) {
      navigate(-1)
    }
  }

  const onChange = (event) => {
    status.roleId = event.target.value
  }

  useEffect(() => {
    getRoles()
    getPermissions()
  }, [])
  return (
    <PageHeader
      title="新建用户"
      style={{ background: '#fff' }}
      onBack={() => {
        navigate(-1)
      }}
    >
      <Card>
        <Form {...layout}>
          <Form.Item label={<span className="label-required">用户账号</span>}>
            <Input
              placeholder="请输入用户账号"
              value={status.username}
              onChange={(event) => {
                status.username = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label={<span className="label-required">用户名称</span>}>
            <Input
              placeholder="请输入用户名称"
              value={status.nickname}
              onChange={(event) => {
                status.nickname = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label={<span className="label-required">邮箱</span>}>
            <Input
              placeholder="请输入邮箱"
              value={status.email}
              onChange={(event) => {
                status.email = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label={<span className="label-required">密码</span>}>
            <Input
              placeholder="请输入密码"
              value={status.password}
              onChange={(event) => {
                status.password = event.target.value
              }}
            />
          </Form.Item>
          <Form.Item label={<span className="label-required">用户角色</span>}>
            <Radio.Group onChange={onChange}>
              {status.roles.map((role, index) => (
                <Radio key={index} value={role.id}>
                  {role.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={<span className="label-required">用户权限</span>}>
            {status.perms
              .filter((i) => status.roles.find((role) => role.id === status.roleId)?.perms_set_ids?.includes(i.id))
              .map((item) => Object.keys(item.permissions).map((per, index) => <Tag key={index}>{per}</Tag>))}
          </Form.Item>
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 22 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  navigate(-1)
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                loading={status.isLoading}
                style={{ marginLeft: 10 }}
                disabled={!status.username || !status.nickname || !status.email || !status.password || !status.roleId}
                onClick={createUser}
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </PageHeader>
  )
}
