import React from 'react'
import { useEffect } from 'react'
import moment from 'moment'
import { PageHeader, Card, Button, Table, Popconfirm } from 'antd'
import { useStore } from 'utils/stateStore'
import { ColumnsType } from 'antd/lib/table'
import Api from 'api/Api'
import { Organization } from 'types/organization'
import { useNavigate } from 'react-router'

class Status {
  orgs: Organization.AsObject[] = []
  page = 1
  page_size = 10
  total: number = undefined
  curOrg: Organization.AsObject = undefined
}

export const OrgApply = () => {
  const { status } = useStore(new Status())
  const navigate = useNavigate()

  const fetchOrg = async () => {
    const getOrgsRes = await Api.organization.listOrganizations({
      state: [Organization.State.Applying],
      page_number: status.page - 1,
      page_size: status.page_size
    })
    status.orgs = getOrgsRes.organizations
    status.total = getOrgsRes.page.total
  }

  useEffect(() => {
    fetchOrg()
  }, [])

  const columns: ColumnsType<Organization.AsObject> = [
    {
      title: '单位名称',
      width: 200,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '申请账号',
      align: 'center',
      dataIndex: 'creator'
    },
    {
      title: '申请用户名称',
      align: 'center',
      dataIndex: 'fullname'
    },
    {
      title: '申请日期',
      align: 'center',
      dataIndex: 'created_at',
      render(value) {
        if (!value || moment(value).valueOf() === -62135596800000) {
          return ''
        }
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      width: 240,
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                navigate(`/admin_portal/org_apply/${record.name}/detail`)
              }}
            >
              详情
            </Button>
            <Popconfirm
              title="确认要批准这个单位申请么？"
              onConfirm={async () => {
                await Api.organization.approvalOrganization(record.name)
                await fetchOrg()
              }}
            >
              <Button type="link" size="small">
                批准
              </Button>
            </Popconfirm>
            <Popconfirm
              title="确认要拒绝这个单位申请么？"
              onConfirm={async () => {
                await Api.organization.refuseOrganization(record.name)
                await fetchOrg()
              }}
            >
              <Button type="link" size="small">
                拒绝
              </Button>
            </Popconfirm>
          </div>
        )
      }
    }
  ]
  return (
    <PageHeader
      title="单位审批"
      style={{
        background: '#FFF'
      }}
    >
      <Card>
        <Table<Organization.AsObject>
          rowKey="id"
          columns={columns}
          dataSource={status.orgs}
          bordered={true}
          pagination={{
            total: status.total,
            pageSize: status.page_size,
            showSizeChanger: false,
            onChange: async (page: number, page_size: number) => {
              status.page = page
              status.page_size = page_size
              await fetchOrg()
            }
          }}
        />
      </Card>
    </PageHeader>
  )
}
