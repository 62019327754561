import { Service } from '../types/service';
import axios from '../utils/axios';

export const listServiceRequests = (params: { state: Service.State[] }): Promise<Service.ServiceRequest[]> => {
  return axios.get('/api/v1/requests/services', { params })
}

export const listOrganizationServices = (
  org_name: string,
  params?: { state?: Service.State[]; i_am_manager: boolean }
): Promise<Service.AsObject[]> => {
  return axios.get(`/api/v1/organizations/${org_name}/services`, { params })
}

export const createOrganizationService = (
  org_name: string,
  params: { name: string; state: Service.State }
): Promise<Service.AsObject> => {
  return axios.post(`/api/v1/organizations/${org_name}/services`, params)
}

export const updateOrganizationService = (
  org_name: string,
  svc_name: string,
  params: {
    state: Service.State | Service.ServiceRequestState
  }
): Promise<void> => {
  return axios.put(`/api/v1/organizations/${org_name}/services/${svc_name}`, params)
}
